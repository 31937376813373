var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류",
            "tabActive": "하지정맥류 치료원칙",
            "bg": "/images/sub/varicose/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--xs primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("Treatment Principles")]), _c('div', {
    staticClass: "tit--lg font-weight-regular"
  }, [_vm._v("하지정맥류 치료 원칙")])])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first"
  }, [_c('div', {
    staticClass: "principle-tree"
  }, [_c('v-sheet', {
    staticClass: "overflow-hidden d-xl-none mb-24px mb-md-48px",
    attrs: {
      "rounded": "",
      "color": "grey lighten-5"
    }
  }, [_c('v-sheet', {
    staticClass: "px-16px py-24px py-md-48px text-center font-secondary",
    attrs: {
      "color": "primary lighten-3"
    }
  }, [_c('u-tit-default', [_c('div', {
    staticClass: "tit--lg primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("비수술적 방법")]), _c('div', {
    staticClass: "tit--xs font-weight-regular grey--text text--darken-4"
  }, [_vm._v("보존적 방법")])])], 1), _c('div', {
    staticClass: "pa-16px pa-md-48px text-center"
  }, [_c('u-tit-default', {
    staticClass: "tit--xs font-weight-regular"
  }, [_c('div', [_vm._v(" 근본적인 치료는 되지 않고, "), _c('br'), _vm._v(" 증상을 조절하는 방법 ")]), _c('div', {
    staticClass: "primary--text mt-8px"
  }, [_vm._v("수술이 싫거나, "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("수술을 할 수 없을 때 사용")])])], 1)], 1), _c('div', {
    staticClass: "principle-tree__btn-group"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "font-secondary principle-tree__btn",
    attrs: {
      "to": "/non-surgical/stockings",
      "flat": "",
      "rounded": "pill",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-card-title', {
    staticClass: "pa-0 justify-center flex-column white--text font-size-12 font-size-md-24 font-size-xl-16"
  }, [_c('div', [_vm._v("압박스타킹")]), _c('v-icon', {
    staticClass: "font-weight-regular white--text mt-4px mt-md-8px"
  }, [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "font-secondary principle-tree__btn",
    attrs: {
      "to": "/non-surgical/drugs",
      "flat": "",
      "rounded": "pill",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-card-title', {
    staticClass: "pa-0 justify-center flex-column white--text font-size-12 font-size-md-24 font-size-xl-16"
  }, [_c('div', [_vm._v("경구약")]), _c('v-icon', {
    staticClass: "font-weight-regular white--text mt-4px mt-md-8px"
  }, [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "font-secondary principle-tree__btn",
    attrs: {
      "to": "/non-surgical/sclerotherapy",
      "flat": "",
      "rounded": "pill",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-card-title', {
    staticClass: "pa-0 justify-center flex-column white--text font-size-12 font-size-md-24 font-size-xl-16"
  }, [_c('div', [_vm._v("혈관경화요법")]), _c('v-icon', {
    staticClass: "font-weight-regular white--text mt-4px mt-md-8px"
  }, [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "overflow-hidden d-xl-none my-24px my-md-48px",
    attrs: {
      "rounded": "",
      "color": "grey lighten-5"
    }
  }, [_c('v-sheet', {
    staticClass: "px-16px py-24px py-md-48px text-center font-secondary",
    attrs: {
      "color": "primary lighten-3"
    }
  }, [_c('u-tit-default', [_c('div', {
    staticClass: "tit--lg primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("수술적 방법")]), _c('div', {
    staticClass: "tit--xs font-weight-regular grey--text text--darken-4"
  }, [_vm._v("근본 치료 방법")])])], 1), _c('div', {
    staticClass: "pa-16px pa-md-48px text-center"
  }, [_c('v-card', {
    staticClass: "pa-24px pa-md-48px",
    attrs: {
      "outlined": "",
      "flat": "",
      "rounded": "",
      "color": "primary"
    }
  }, [_c('u-tit-default', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit--lg mb-8px mb-md-14px"
  }, [_vm._v("외과적 제거")]), _c('div', {
    staticClass: "tit--xs primary--text font-weight-regular mb-16px mb-md-32px"
  }, [_vm._v("고위결찰술 및 발거술")]), _c('div', {
    staticClass: "tit--xs grey--text font-weight-regular"
  }, [_vm._v(" 치료 대상 혈관을 피부 절개 후 "), _c('br'), _vm._v(" 물리적으로 잡아당겨 제거하는 "), _c('br'), _vm._v(" 전통적 방법 ")])])], 1), _c('v-card', {
    staticClass: "mt-16px mt-md-32px pa-24px pa-md-48px",
    attrs: {
      "outlined": "",
      "flat": "",
      "rounded": "",
      "color": "primary"
    }
  }, [_c('u-tit-default', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit--lg mb-8px mb-md-14px"
  }, [_vm._v("정맥내 제거 수술")]), _c('div', {
    staticClass: "tit--xs primary--text font-weight-regular mb-16px mb-md-32px"
  }, [_vm._v(" 클라리베인 "), _c('br'), _vm._v(" 베나실 "), _c('br'), _vm._v(" 플레보그립 "), _c('br'), _vm._v(" 레이저 "), _c('br'), _vm._v(" 고주파 ")]), _c('div', {
    staticClass: "tit--xs grey--text font-weight-regular"
  }, [_vm._v(" 치료 대상 혈관을 내시경 처럼 "), _c('br'), _vm._v(" 혈관 안에서 치료하는 방법 ")])])], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "d-xl-none px-30px px-md-60px py-24px py-md-48px",
    attrs: {
      "rounded": "",
      "color": "#FF6D05"
    }
  }, [_c('u-txt-default', {
    staticClass: "font-size-md-28px font-weight-bold white--text text-center"
  }, [_vm._v(" 현재 미국, 유럽 등에서는 ‘정맥내요법’을 "), _c('br'), _vm._v(" 하지정맥류의 표준치료법으로 제시하고 있습니다. ")])], 1), _c('v-img', {
    staticClass: "d-none d-xl-block principle-tree__bg",
    attrs: {
      "src": "/images/sub/varicose/principle-tree.png"
    }
  })], 1)]), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--last grey lighten-5"
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary font-weight-regular"
  }, [_vm._v("미국 및 영국 "), _c('strong', [_vm._v("정맥학회 지침")])])], 1), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-secondary font-weight-regular mb-16px mb-md-24px"
  }, [_vm._v("미국 정맥학회 지침")]), _c('v-divider', {
    staticClass: "mb-24px mb-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-default', [_c('strong', {
    staticClass: "txt--lg txt--dark d-block mb-16px mb-md-24px"
  }, [_vm._v("Phlebology 2012;27 Suppl 1:2-9.")]), _c('div', [_vm._v("(7) For treatment of the incompetent GSV, endovenous thermal ablation (radiofrequency, RF, of endovenous laser therapy, EVLT) is recommended over high ligation and stripping of the saphenous vein. Recommendation: GRADE 1 (strong), level of evidence: B (medium queality)")]), _c('div', {
    staticClass: "txt--dark font-weight-medium mt-4px mt-md-8px"
  }, [_vm._v("(7) 하지정맥류의 치료는 정맥내치료(고주파, 레이저 등)가 고위결찰발거술보다 더 우선적으로 추천된다. (강력한 추천 등급)")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/varicose/guide-us-01.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/varicose/guide-us-02.jpg"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-secondary font-weight-regular mb-16px mb-md-24px"
  }, [_vm._v("영국 정맥학회 지침")]), _c('v-divider', {
    staticClass: "mb-24px mb-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-default', [_c('strong', {
    staticClass: "txt--lg txt--dark d-block mb-16px mb-md-24px"
  }, [_vm._v("NICE guideline :Varicose veins in the legs 2013 10-11p")]), _c('div', {
    staticClass: "mb-4px mb-md-8px"
  }, [_vm._v("Interventional treatment")]), _c('div', {
    staticClass: "txt--dark font-weight-medium"
  }, [_vm._v("하지정맥류를 수술할 경우")]), _c('ul', {
    staticClass: "dot-list dot-list--sm mt-16px mt-md-24px"
  }, [_c('li', [_vm._v("Offer endothermal ablation (RFA) and EVLT")]), _c('li', {
    staticClass: "txt--dark font-weight-medium"
  }, [_vm._v("고주파나 레이저 같은 정맥내 요법으로 수술하세요.")]), _c('li', [_vm._v("If endothermal ablation is unsuitable, offer ultrasound-guided foam sclerotherapy")]), _c('li', {
    staticClass: "txt--dark font-weight-medium"
  }, [_vm._v("이런 정맥내 요법이 안되는 경우, 약물요법(경화제)으로 치료하세요.")]), _c('li', [_vm._v("If ultrasound-guided foam sclerotherapy is unsuitable, offer surgery")]), _c('li', {
    staticClass: "txt--dark font-weight-medium"
  }, [_vm._v("그리고 이러한 약물요법도 안되는 경우 발거술을 시행하세요.")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/varicose/guide-uk-01.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/varicose/guide-uk-02.jpg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }