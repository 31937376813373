var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류 수술",
            "tabActive": "플레보그립",
            "bg": "/images/sub/surgery/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류 수술")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--lg font-weight-regular mb-8px mb-md-16px"
  }, [_vm._v("플레보그립")]), _c('div', {
    staticClass: "tit--xs primary--text text--darken-4"
  }, [_vm._v("Flebogrif (MOCA: Mechanico-Chemical Ablation)")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_c('strong', {
    staticClass: "d-block mb-4px mb-md-8px"
  }, [_vm._v("플레보그립은 원칙적으로 클라리베인과 원리가 같아 장단점이 비슷합니다.")]), _c('div', [_vm._v("작용범위, 사용가능 약물 등이 달라, 환자의 체형, 하지정맥류 상태에 따라 클라리베인과 플레보그립 중 선택해서 사용할 수 있습니다.")])])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first page-section--last"
  }, [_c('v-img', {
    staticClass: "border-x border-y rounded",
    attrs: {
      "src": "/images/sub/surgery/flebogrif.png"
    }
  }), _c('div', {
    staticClass: "pt-40px pt-md-60px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("특징 및 장점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("정맥내요법 중 열을 사용하지 않는 비열치료법 중 하나")]), _c('li', [_vm._v("열에 의한 신경손상이 없음")]), _c('li', [_vm._v("시술 중 통증이 적어 전신마취나 척추마취 없이 수면마취로 수술이 가능")]), _c('li', [_vm._v("추가적인 정맥절제술이 필요 없어 흉터가 거의 없음")]), _c('li', [_vm._v("퇴원 후 즉시 일상 생활 가능")]), _c('li', [_vm._v(" 미국, 유럽 및 대한민국 식약처(FDA, CE, KFDA)의 안전성 시험을 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 정식으로 거친 후 허가된 방법 ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("단점 및 부작용")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("상대적으로 고가인 수술비")]), _c('li', [_vm._v("일시적 정맥염 (낮은 가능성)")]), _c('li', [_vm._v("심부정맥혈전증 (매우 희박)")])])])], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "overflow-hidden mt-40px mt-md-60px",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 16 / 9
    }
  }, [_c('iframe', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "src": "https://www.youtube.com/embed/SaF2CGhPy-E?si=uqci_GB_1-CV28Hm",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  })])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }