var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류 수술",
            "tabActive": "레이저",
            "bg": "/images/sub/surgery/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류 수술")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--lg font-weight-regular mb-8px mb-md-16px"
  }, [_vm._v("레이저 수술")]), _c('div', {
    staticClass: "tit--xs primary--text text--darken-4"
  }, [_vm._v("EVLT : Endovenous LASER Treatment")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 문제가 있는 정맥 안에 레이저 광섬유를 삽입한 후 레이저 에너지를 통해 정맥의 내막을 태워 혈관을 수축시켜 막는 방법입니다. ")])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first"
  }, [_c('v-sheet', {
    staticClass: "pa-20px py-md-68px",
    attrs: {
      "rounded": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "526",
      "src": "/images/sub/surgery/laser.jpg"
    }
  })], 1), _c('v-sheet', {
    staticClass: "primary lighten-5 mt-40px mt-md-60px pa-24px px-lg-80px py-lg-60px",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "508",
      "src": "/images/sub/surgery/laser-image-01.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "508",
      "src": "/images/sub/surgery/laser-image-02.jpg"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "pt-40px pt-md-60px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("특징 및 장점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("수술부위 절개가 없어 흉터 및 수술 후 통증이, 발거술 보다 현저히 낮음")]), _c('li', [_vm._v("15년 이상 사용으로 축적된 경험이 많음")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("단점 및 부작용")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v(" 고온(800-1200도 이상)을 사용하기 때문에 이론적으로는 열에 의한 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 주변 조직 손상 등의 부작용이 발생할 수 있음 ")]), _c('li', [_vm._v("하지정맥류가 많이 진행되어 정맥이 너무 많이 구불구불한 경우 시행이 어려움")]), _c('li', [_vm._v(" 레이저 수술법의 단점을 보완한 새로운 수술법들이 많이 개발되어"), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 상대적 약점이 있음 ")])])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }