var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-secondary', {
          attrs: {
            "itemTitle": "치료비용 안내",
            "bg": "/images/sub/cost/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("치료비용 안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--xs primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("Cost guide")]), _c('div', {
    staticClass: "tit--lg font-weight-regular"
  }, [_vm._v("편안하지 수술비 안내")])])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first page-section--last"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "font-weight-regular"
  }, [_vm._v(" 편안하지흉부외과는 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('strong', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("‘수술비용’")]), _vm._v(" 및 "), _c('strong', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("‘수술종류’")]), _vm._v("에 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 환자의 상태를 맞추지 않습니다. ")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v("편안하지흉부외과는 모든 수술을 다 시행하고, 또 수술 종류에 따른 수술비용의 차이가 없습니다.")])], 1), _c('v-sheet', {
    staticClass: "pa-24px pa-md-60px grey--text text--darken-4",
    attrs: {
      "rounded": "",
      "color": "primary lighten-5"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm text-center line-height-15"
  }, [_vm._v(" 1. 수술은 크게 3그룹 (A 발거술, B 열치료(고주파/레이저), C 비열치료(클라리베인/베나실/플레보그립))으로 나뉘고 각 그룹별 수술비용의 차이는 있지만, 그룹내 수술 종류에 따른 수술 비용의 차이는 없습니다.")]), _c('v-card', {
    staticClass: "rounded-xl rounded-md-pill font-secondary pa-16px pa-md-24px my-24px my-md-40px mx-auto",
    attrs: {
      "flat": "",
      "outlined": "",
      "color": "primary",
      "max-width": "560"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-12px pa-md-16px font-secondary",
    attrs: {
      "rounded": "pill",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('u-txt-default', {
    staticClass: "text-center txt--light line-height-1"
  }, [_c('div', {
    staticClass: "mb-4px mb-md-8px"
  }, [_c('strong', [_vm._v("A 발거술")])]), _c('div', {
    staticClass: "txt--sm"
  }, [_vm._v("발거술")])])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-12px pa-md-16px",
    attrs: {
      "rounded": "pill",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('u-txt-default', {
    staticClass: "text-center txt--light line-height-1"
  }, [_c('div', {
    staticClass: "mb-4px mb-md-8px"
  }, [_c('strong', [_vm._v("B 열치료")])]), _c('div', {
    staticClass: "txt--sm"
  }, [_vm._v("고주파 / 레이저")])])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-12px pa-md-16px",
    attrs: {
      "rounded": "pill",
      "color": "primary"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('u-txt-default', {
    staticClass: "text-center txt--light line-height-1"
  }, [_c('div', {
    staticClass: "mb-4px mb-md-8px"
  }, [_c('strong', [_vm._v("C 비열치료")])]), _c('div', {
    staticClass: "txt--sm"
  }, [_vm._v("클라리베인 / 베나실 / 플레보그립")])])], 1)], 1)], 1)], 1)], 1), _c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular text-center"
  }, [_c('div', {
    staticClass: "mb-8px mb-md-16px"
  }, [_vm._v("즉, "), _c('strong', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("환자의 상태에 따라 최선의 수술방법을 선택")]), _vm._v("합니다.")]), _c('div', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("수술비용 또는 원장의 편의에 따라 수술방법을 결정하지 않습니다.")])])], 1), _c('v-sheet', {
    staticClass: "mt-16px mt-md-24px pa-24px pa-md-60px grey--text text--darken-4",
    attrs: {
      "rounded": "",
      "color": "primary lighten-5"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm text-center line-height-15"
  }, [_vm._v("2. 수술 혈관 개수에 따른 수술 비용의 차이가 없습니다. ")]), _c('u-txt-default', {
    staticClass: "text-center mt-24px mt-md-40px"
  }, [_c('div', {
    staticClass: "txt--lg mb-8px mb-md-16px"
  }, [_vm._v("‘줄기(뿌리) 당 얼마’의 식으로 수술비용을 계산하지 않습니다.")]), _c('div', {
    staticClass: "txt--xl"
  }, [_c('strong', [_vm._v("따라서, "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("꼭 수술이 필요한 혈관")]), _vm._v("에만 수술을 시행합니다.")])])])], 1), _c('v-sheet'), _c('main-cost-table', {
    staticClass: "d-md-none pt-40px",
    attrs: {
      "mobile": ""
    }
  }), _c('main-cost-table', {
    staticClass: "d-none d-md-block pt-md-60px"
  }), _c('v-sheet', {
    staticClass: "mt-60px mt-md-80px",
    attrs: {
      "id": "non-benefit"
    }
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary font-weight-regular"
  }, [_vm._v("편안하지흉부외과 비급여 세부항목 고시")])], 1), _c('v-divider', {
    staticStyle: {
      "border-color": "var(--v-grey-darken)"
    }
  }), _c('div', {
    staticClass: "non-benefit-table"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/cost/cost-non-benefit.png"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }