<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류" tabActive="하지정맥류란?" bg="/images/sub/varicose/visual.jpg">
                <template #title>하지정맥류</template>
            </sub-head-primary>
        </template>

        <u-tit-wrap-default class="tit-wrap--lg text-center">
            <u-tit-default class="font-secondary">
                <div class="tit--xs primary--text text--darken-4 mb-8px mb-md-16px">Varicose vein</div>
                <div class="tit--lg font-weight-regular">하지정맥류란?</div>
            </u-tit-default>
        </u-tit-wrap-default>

        <page-section-primary class="page-section--xs page-section--first page-section--last">
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-default class="font-weight-regular mb-16px mb-24px"><strong>하지정맥류</strong>는 <strong>대표적인 혈관질환</strong>입니다.</u-tit-default>
                <u-txt-default class="txt--lg">
                    다리 정맥 내부에서 혈액의 역류를 막아주는 판막이 손상되어<br class="d-none d-lg-block" />
                    혈액이 심장으로 가지 못하고 정체되거나 역류하면서 발생하는 대표적인 혈관 질환입니다.
                </u-txt-default>
            </u-tit-wrap-default>

            <v-sheet outlined rounded class="px-16px py-24px py-md-40px">
                <v-img src="/images/sub/varicose/varicose-vein.png" max-width="778" class="mx-auto" />
            </v-sheet>

            <div class="mt-60px mt-md-80px">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card flat rounded="pill" color="grey lighten-5 number-card font-weight-bold font-secondary py-12px pl-86px mb-24px mb-md-40px">
                            <div>
                                <v-avatar size="60" color="primary" class="number font-size-28">01</v-avatar>
                            </div>
                            <u-tit-default class="tit--sm">선천적요인</u-tit-default>
                        </v-card>

                        <u-txt-default class="txt--lg px-24px pl-md-40px pr-md-0">
                            <div>
                                선천적으로 다리 정맥의 판막이 약한 것이 가장 중요한 원인입니다. <br class="d-none d-xl-block" />
                                같은 환경이라도 선천적 요인이 있는 사람은 쉽게 판막이 손상됩니다. <br class="d-none d-xl-block" />
                                따라서 하지정맥류는 '가족력'이 매우 강한 질환입니다.
                            </div>
                            <div class="mt-8px mt-md-16px grey--text text--lighten-1">
                                부모 중 한명이 환자일 때 아들은 20-30%, <br class="d-sm-none">딸은 60-70%에서 발생 <br />
                                부모 둘다 환자인 경우 아들, 딸 중 90% 이상에서 발생
                            </div>
                            <v-img src="/images/sub/varicose/cause-01.jpg" class="mt-24px mt-md-40px rounded" />
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card flat rounded="pill" color="grey lighten-5 number-card font-weight-bold font-secondary py-12px pl-86px mb-24px mb-md-40px">
                            <div>
                                <v-avatar size="60" color="primary" class="number font-size-28">02</v-avatar>
                            </div>
                            <u-tit-default class="tit--sm">후천적요인</u-tit-default>
                        </v-card>

                        <u-txt-default class="txt--lg px-24px pl-md-40px pr-md-0">
                            오래 서있는 직업, 고령, 임신, 비만, 피임약, 호르몬치료, 여성 등이 <br class="d-none d-xl-block" />
                            판막 손상의 고위험 인자입니다.

                            <v-img src="/images/sub/varicose/cause-02.png" class="mt-24px mt-md-142px rounded" />
                        </u-txt-default>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.number-card {
    position: relative;
    overflow: visible;
    .number {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        overflow: visible;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 24px);
            height: calc(100% + 24px);
            border: 12px solid var(--v-grey-lighten5);
            border-radius: 50%;
            z-index: -1;
        }
    }
}
</style>
