var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm.$vuetify.breakpoint.xlOnly ? _c('header-secondary') : [_c('mobile-header-primary'), _c('mobile-nav-primary')], _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_vm._t("contentsHead"), _c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_vm._t("default")], 2), _vm._t("contentsFoot")], 2), _c('footer-primary'), _c('floating-buttons'), _c('btn-top-primary', _vm._b({
    staticClass: "d-xl-none",
    attrs: {
      "isMain": ""
    }
  }, 'btn-top-primary', {
    isMain: _vm.isMain
  }, false))], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }