var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류",
            "tabActive": "나도 하지정맥류?",
            "bg": "/images/sub/varicose/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--xs primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("Self-diagnosis")]), _c('div', {
    staticClass: "tit--lg font-weight-regular"
  }, [_vm._v("하지정맥류 자가진단")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 하지정맥류는 장기간에 걸쳐 증상이 나타나므로 "), _c('br', {
    staticClass: "d-sm-none"
  }), _vm._v("치료시기를 놓치기 쉽습니다. "), _c('br'), _vm._v(" 조금이라도 증상이 의심된다면, "), _c('br', {
    staticClass: "d-sm-none"
  }), _vm._v("미리 자가 진단해보세요. ")])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--last primary lighten-5"
  }, [_c('v-sheet', {
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "height": "100%",
      "src": "/images/sub/varicose/self-diagnosis.jpg"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-center d-xl-block",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-24px pa-md-60px",
    attrs: {
      "max-width": "600"
    }
  }, [_c('div', [_c('div', {
    staticClass: "mb-16px mb-md-24px"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "diagnosis-icon",
    attrs: {
      "src": "/images/icon/icon-checked-document.svg"
    }
  })], 1), _c('v-col', [_c('u-tit-default', {
    staticClass: "font-weight-regular font-secondary line-height-1"
  }, [_vm._v("하지정맥류 관련 증상")])], 1)], 1)], 1), _vm._l(_vm.items, function (item) {
    return [_c('div', {
      key: item
    }, [_c('v-row', {
      staticClass: "row--xxs align-md-center my-0"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-img', {
      staticClass: "d-lg-none mt-4px",
      attrs: {
        "src": "/images/icon/icon-checkbox-dark.svg",
        "max-width": "12px"
      }
    }), _c('v-img', {
      staticClass: "d-none d-lg-block",
      attrs: {
        "src": "/images/icon/icon-checkbox-dark.svg",
        "max-width": "18px"
      }
    })], 1), _c('v-col', [_c('p', {
      staticClass: "font-size-14 font-size-md-16"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])], 1)], 1)];
  }), _c('u-txt-default', {
    staticClass: "txt--lg primary--text pt-8px pt-md-16px line-height-135"
  }, [_vm._v(" 해당 개수가 많을수록 정맥순환에 문제가 있을 수 있기 때문에 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 전문의에게 정확한 진단을 받는 것이 좋습니다. ")])], 2), _c('div', {
    staticClass: "mt-24px mt-md-40px"
  }, [_c('div', {
    staticClass: "mb-16px mb-md-24px"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "diagnosis-icon",
    attrs: {
      "src": "/images/icon/icon-alarm.svg"
    }
  })], 1), _c('v-col', [_c('u-tit-default', {
    staticClass: "font-weight-regular font-secondary line-height-1"
  }, [_vm._v("확진")])], 1)], 1)], 1), _c('u-txt-default', {
    staticClass: "txt--lg mb-16px mb-md-24px"
  }, [_vm._v(" 다리에 "), _c('strong', [_vm._v("혈관이 울퉁불퉁")]), _vm._v(" 튀어 나와 있거나 "), _c('strong', [_vm._v("파란 혈관")]), _vm._v("이 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 많이 두드러지게 보인다. ")]), _c('u-txt-default', {
    staticClass: "txt--lg reddish--text line-height-135"
  }, [_vm._v(" 이런 경우 하지정맥류는 확실합니다. "), _c('br'), _vm._v(" 그러나 치료의 필요성, 치료 범위, 치료 방법 등을 확인하기 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 위해서는 병원에 방문하여 초음파 검사를 시행하여야 합니다. ")])], 1)])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }