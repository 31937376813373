<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="편안하지흉부외과" tabActive="의료진 소개" bg="/images/sub/introduction/visual.jpg">
                <template #title>편안하지흉부외과</template>
            </sub-head-primary>
        </template>

        <u-tit-wrap-default class="tit-wrap--lg text-center">
            <u-tit-default class="font-secondary">
                <div class="tit--xs primary--text text--darken-4 mb-8px mb-md-16px">Medical team</div>
                <div class="tit--lg font-weight-regular">의료진 소개</div>
            </u-tit-default>
        </u-tit-wrap-default>

        <page-section-primary class="page-section--sm secondary darken-4 section-bg">
            <v-row align="end" class="row--xs">
                <v-col cols="12" md="7" lg="7">
                    <div class="py-md-48px">
                        <u-tit-wrap-default class="tit-wrap--lg">
                            <v-img max-width="542" src="/images/sub/introduction/team/team-slogan.png" />
                        </u-tit-wrap-default>

                        <u-tit-wrap-default class="tit-wrap--sm">
                            <u-tit-default class="font-secondary font-weight-regular">
                                <div class="tit--xs mb-8px mb-md-16px primary--text">내과 및 흉부심혈관외과 전문의</div>
                                <div><strong>이승철</strong> 대표원장</div>
                            </u-tit-default>
                        </u-tit-wrap-default>
                        <u-txt-default class="txt--lg txt--light">
                            우리나라 최고의 혈관수술 병원인 서울아산병원에서 10년 넘게 정식으로 <br class="d-none d-lg-block" />
                            수련 받고 임상강사로 근무하면서 하지정맥류 치료 및 수술의 노하우를 쌓았습니다.
                        </u-txt-default>
                        <v-img src="/images/sub/introduction/team/asan-logo.svg" max-width="218" class="w-50 w-md-40 mt-8px mt-md-16px" />
                    </div>
                </v-col>
                <v-col cols="12" md="5" lg="5" class="doctor-bg" />
            </v-row>
        </page-section-primary>

        <page-section-primary class="page-section--sm">
            <v-row>
                <v-col cols="12" lg="6">
                    <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">프로필</u-tit-default>
                    <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                    <u-txt-default class="txt--lg font-size-md-18">
                        <ul class="square-list">
                          
                            <li>가톨릭의과대학교(학사, 학과장상 졸업)</li>
                            <li>울산대학교(서울아산병원) 대학원(석사)</li>
                            <li>서울대학교 대학원(박사)</li>
                            <li>서울아산병원 흉부심장혈관외과 전공의</li>
                            <li>서울아산병원 흉부심장혈관외과 임상강사</li>
                            <li>대한흉부심장혈관외과학회 정회원(전문의)</li>
                            <li>대한정맥학회 정회원 및 학술위원</li>
                            <li>대한 결핵및호흡기학회 정회원(전문의)-내과/외과 계열 동시 전문의</li>
                            <li>미국정맥학회(American College of Phlebology) 회원</li>
                        </ul>
                    </u-txt-default>
                </v-col>
                <v-col cols="12" lg="6">
                    <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">학회 및 연수활동</u-tit-default>
                    <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                    <u-txt-default class="txt--lg font-size-md-18">
                        <ul class="square-list">
                            <li>대한흉부심장혈관외과학회 정회원(전문의)</li>
                            <li>대한정맥학회 정회원 및 학술위원(전문의)</li>
                            <li>대한 결핵및호흡기학회 정회원(전문의)</li>
                            <li>미국정맥학회 (American College of Phlebology) 회원</li>
                            <li>WHO LTBI guideline development consultation meeting, Geneva, board member. 2014</li>
                            <li>The STS(The Society of Thoracic Surgeons, 미국흉부외과학회) -47th Annual Metting in San Diego, California, 2011 연자발표</li>
                            <li>The 3rd Congress of Asia-Pacific Pediatric Cardiac Society (아시아흉부외과학회), Osaka Japan.2011 연자발표</li>
                            <li>World Congress of Internal Medicine, Seoul, Korea, 2014 연자발표</li>
                            <li>The 3rd Meeting of Asian TB Experts Community, Macau, 2014 연자 발표</li>
                            <li>World TB Advisory Board Meeting, Seoul, Korea 2015 연자 발표</li>
                            <li>대한정맥학회 2017 춘계학회 연자발표 :Scierotherapy - Procedure and Posttreatment Management</li>
                            <li>대한흉부심장혈관외과의사회 2018 춘계학회 연자 발표 : Varicose veins treatment with Venaseal</li>
                            <li>대한흉부심장혈관외과의사회 2018 추계학회 연자 발표 : Comparison between Non thermal &amp; Thermal ablation treatment in varicose vein</li>
                            <li>
                                APECS(Advances in Peripheral EndovasCular Strategies) <br class="d-none d-xl-block" />
                                2018 연자 발표: Big match comes true: Venaseal vs Clarivein
                            </li>
                            <li>
                                APECS(Advances in Peripheral EndovasCular Strategies) <br class="d-none d-xl-block" />
                                2019 연자 발표: Do we need to make even distribution of sclerosant during MOCA?
                            </li>
                            <li>대한정맥학회 2020 추계학회 연자 발표: The latest update on history of foam therapy</li>
                            <li>서울시의사회 의사대상 강의 2021: 하지정맥류의 진단과 치료</li>
                            <li>대한흉부심장혈관외과학회 하지정맥연구회 2022 추계학회 연자 발표: Reflux of distal SSV contributed by reflux of posterior arch vein</li>
                            <li>Merit Medical World Wide Webina : Top 7 tips for Clarivein 2022</li>
                            <li>대한정맥학회 2023 춘계학회 연자 발표: How to avoid &amp; manage complications after Non-Thermal Ablation</li>
                            <li>대한정맥학회 – 하지정맥류 진단을 위한 근거 중심의 초음파 검사법 집필 위원 (2023)</li>
                            <li>대한의학회 – 하지정맥류 초음파검사 진료지침 개발위원(5인중 1인) (2023)</li>
                        </ul>
                    </u-txt-default>
                </v-col>
            </v-row>
        </page-section-primary>

        <page-section-primary class="page-section--sm primary lighten-4 grey--text text--darken-4">
            <u-tit-wrap-default class="text-center">
                <u-tit-default class="font-weight-regular mb-16px mb-24px">학회활동</u-tit-default>
                <u-txt-default class="txt--lg grey--text text--darken-3">
                    편안하지흉부외과 이승철 원장은 매년 학회발표를 통해, <br class="d-md-none d-lg-block" />
                    앞서가는 경험을 국내외 여러 의사들과 공유하고 있습니다.
                </u-txt-default>
            </u-tit-wrap-default>

            <div class="main-slide main-slide--academy">
                <swiper :options="academyOptions">
                    <template v-for="item in academy">
                        <swiper-slide :key="item">
                            <img :src="item.src" alt="" class="w-100 rounded d-block" />
                        </swiper-slide>
                    </template>

                    <div class="swiper-control" slot="pagination" @click.prevent>
                        <v-row no-gutters justify="space-between" align="center">
                            <v-col cols="auto">
                                <v-btn color="primary darken-4" outlined icon class="swiper-button-prev v-size--xx-large">
                                    <v-icon class="primary--text">chevron_left</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn color="primary darken-4" outlined icon class="swiper-button-next v-size--xx-large">
                                    <v-icon class="primary--text">chevron_right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </swiper>
            </div>

            <div class="thumbs-slide thumbs-slide--academy">
                <swiper :options="academyThumbs">
                    <template v-for="item in academy">
                        <swiper-slide :key="item">
                            <img :src="item.src" alt="" class="w-100 rounded-sm d-block" />
                        </swiper-slide>
                    </template>
                </swiper>
            </div>
        </page-section-primary>

        <page-section-primary class="page-section--sm">
            <u-tit-wrap-default class="text-center">
                <u-tit-default class="font-weight-regular mb-16px mb-24px">교류 및 연수활동</u-tit-default>
                <u-txt-default class="txt--lg grey--text text--darken-3"> 편안하지흉부외과에는 매년 국내외 많은 의사들이 이승철 원장님의 경험을 배우기 위해 방문하고 있습니다. </u-txt-default>
            </u-tit-wrap-default>

            <div class="main-slide main-slide--interact">
                <swiper :options="interactOptions">
                    <template v-for="item in interact">
                        <swiper-slide :key="item">
                            <img :src="item.src" alt="" class="w-100 rounded d-block" />
                        </swiper-slide>
                    </template>

                    <div class="swiper-control" slot="pagination" @click.prevent>
                        <v-row no-gutters justify="space-between" align="center">
                            <v-col cols="auto">
                                <v-btn color="primary darken-4" outlined icon class="swiper-button-prev v-size--xx-large">
                                    <v-icon class="primary--text">chevron_left</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn color="primary darken-4" outlined icon class="swiper-button-next v-size--xx-large">
                                    <v-icon class="primary--text">chevron_right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </swiper>
            </div>

            <div class="thumbs-slide thumbs-slide--interact">
                <swiper :options="interactThumbs">
                    <template v-for="item in interact">
                        <swiper-slide :key="item">
                            <img :src="item.src" alt="" class="w-100 rounded-sm d-block" />
                        </swiper-slide>
                    </template>
                </swiper>
            </div>
        </page-section-primary>

        <section class="heart-bg secondary">
            <v-container>
                <v-row justify="end" no-gutters>
                    <v-col cols="12" xl="6">
                        <div class="w-100 h-100 d-flex align-center py-60px py-lg-94px">
                            <div class="pl-xl-60px">
                                <u-tit-wrap-default>
                                    <u-tit-default class="font-secondary font-weight-regular">
                                        하지정맥류는 <br />
                                        <strong class="primary--text">흉부심장혈관외과 전문의</strong>가 <br />
                                        잘한다!
                                    </u-tit-default>
                                </u-tit-wrap-default>
                                <u-txt-default class="txt--lg txt--light line-height-17 mb-16px mb-md-24px">
                                    하지정맥류는 정맥(심장으로 혈액을 되돌려 보내는 혈관) <br />
                                    내부의 판막이 손상되어 심장으로 혈액이 못가고 역류하는 질환입니다. <br />
                                    따라서 심장과 혈관을 모두 잘 이해하는 ‘흉부심장혈관외과 전문의’에게 진료를 받는 것이 중요합니다.
                                </u-txt-default>
                                <u-tit-default class="font-secondary font-weight-regular line-height-135 line-height-md-1">
                                    <div class="tit--xs mb-8px mb-md-16px">흉부외과의 정식 명칭은 ‘흉부심장혈관외과’로,</div>
                                    <div class="tit--sm"><strong>심장과 혈관을 전문적으로 진료</strong>하는 곳입니다.</div>
                                </u-tit-default>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <page-section-primary class="page-section--sm">
            <u-tit-wrap-default class="text-center">
                <u-tit-default class="font-weight-regular mb-16px mb-24px"
                    ><strong class="primary--text">실력있는 흉부심장혈관외과</strong>의 <br class="d-md-none" />
                    정확한 진단</u-tit-default
                >
                <u-txt-default class="txt--lg grey--text text--darken-3">편안하지흉부외과 이승철 원장님은 혈관 초음파를 통해 하지정맥을 일으키는 원인 혈관 및 미세 혈관까지 정확히 진단합니다.</u-txt-default>
            </u-tit-wrap-default>
            <div>
                <v-row class="row--xs">
                    <v-col cols="4">
                        <v-card outlined rounded class="h-100 d-flex justify-center align-center overflow-hidden">
                            <v-img src="/images/sub/introduction/team/diagnosis-01.jpg" max-width="280" class="w-100 mx-auto label-img">
                                <u-txt-default>초음파, H-60</u-txt-default>
                            </v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="8">
                        <v-img src="/images/sub/introduction/team/diagnosis-02.png" class="rounded" />
                    </v-col>
                </v-row>
            </div>
            <div class="pt-40px pt-md-60px">
                <v-row class="row--xs">
                    <v-col cols="6">
                        <v-img src="/images/sub/introduction/team/diagnosis-03.png" class="rounded" />
                    </v-col>
                    <v-col cols="6">
                        <v-img src="/images/sub/introduction/team/diagnosis-04.png" class="rounded" />
                    </v-col>
                </v-row>
            </div>
            <u-txt-default class="txt--lg text-center mt-40px mt-md-60px">
                편안하지에서 하지정맥류 진단 및 치료에 사용되는 혈관 초음파 장비는 영상에서도 바늘이 뚜렷하게 보이도록 도와주는 Needle Mate 기능이 있으며, <br class="d-none d-xl-block" />
                하지정맥류 진단 및 치료를 안전하고 정확하게 할 수 있는 가장 적합한 초음파 장비입니다.
            </u-txt-default>
        </page-section-primary>

        <page-section-primary class="page-section--sm page-section--last grey lighten-5">
            <u-tit-wrap-default class="text-center">
                <u-tit-default class="font-weight-regular mb-16px mb-24px"> <strong>하지정맥류</strong> 관련 현존하는 <br class="d-md-none" /><strong class="primary--text text--darken-4">6가지 근본수술 모두 시행</strong></u-tit-default>
                <u-txt-default class="txt--lg grey--text text--darken-3">환자의 상태를 정확히 진단하고, 그에 따른 가장 좋은 수술법을 선택합니다. </u-txt-default>
            </u-tit-wrap-default>

            <v-row class="ma-n6px ma-md-n20px">
                <template v-for="item in surgery">
                    <v-col cols="4" lg="2" class="pa-6px pa-md-20px" :key="item">
                        <main-surgery-dialog v-bind="item">
                            <template #activator="{ on, attrs }">
                                <v-card v-bind="attrs" v-on="on" flat tile color="transparent" class="surgery-card">
                                    <div class="v-card__image-wrap rounded overflow-hidden">
                                        <v-img :src="item.thumb" class="v-card__image">
                                            <div class="more-view d-flex justify-center align-center mb-2px mb-sm-4px mb-md-6px">
                                                <u-txt-default class="txt--xs font-size-md-16 primary--text text--darken-4 line-height-1">More View</u-txt-default>
                                                <v-img src="/images/icon/icon-plus.svg" max-width="16" class="w-10px w-md-100 ml-4px ml-md-8px" />
                                            </div>
                                        </v-img>
                                    </div>
                                    <v-card-title class="justify-center font-secondary mt-8px mt-md-24px px-0 py-12px py-md-16px">
                                        <div class="text-center">
                                            <u-tit-default class="tit--xs font-weight-medium line-height-1">{{ item.name_ko }}</u-tit-default>
                                        </div>
                                    </v-card-title>
                                </v-card>
                            </template>
                        </main-surgery-dialog>
                    </v-col>
                </template>
            </v-row>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainSurgeryDialog from "@/components/client/main/surgery/main-surgery-dialog.vue";

const academy = [
    //
    { src: "/images/sub/introduction/team/academy-slide-01.jpg" },
    { src: "/images/sub/introduction/team/academy-slide-02.jpg" },
    { src: "/images/sub/introduction/team/academy-slide-03.jpg" },
    { src: "/images/sub/introduction/team/academy-slide-04.jpg" },
    { src: "/images/sub/introduction/team/academy-slide-05.jpg" },
];
const interact = [
    //
    { src: "/images/sub/introduction/team/interact-slide-01.jpg" },
    { src: "/images/sub/introduction/team/interact-slide-02.jpg" },
    { src: "/images/sub/introduction/team/interact-slide-03.jpg" },
    { src: "/images/sub/introduction/team/interact-slide-04.jpg" },
    { src: "/images/sub/introduction/team/interact-slide-05.jpg" },
];
const surgery = [
    {
        name_ko: "클라리베인",
        name_en: "Clarivein™ MOCA;Mechanochemical Ablation",
        subject: "망가진 혈관을 (기계적+화학적)으로 상처를 줘서 제거하는 방법",
        contents: [
            //
            "수술 시간 : 30-40분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 신경손상이 없고, 부작용이 가장 적은 수술",
            "단점 : 거의 없음",
            "최적 적용 대상 : 혈관 사이즈에 비해 역류 및 증상이 심한 환자, 젊은 환자, 여성 환자 등",
            "비용 : 600-650만원",
        ],
        thumb: "/images/main/surgery/surgery-01.jpg",
        photo: "/images/main/surgery/surgery-dialog-01.jpg",
        to: "/surgery/clarivein",
    },
    {
        name_ko: "플레보그립",
        name_en: "Flebogrif™MOCA;Mechanochemical Ablation",
        subject: "망가진 혈관을 (기계적+화학적)으로 상처를 줘서 제거하는 방법, \r\n클리라베인과 기본 원리가 같음",
        contents: [
            //
            "수술 시간 : 30-40분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 신경손상이 없고, 부작용이 가장 적은 수술",
            "단점 : 거의 없음",
            "최적 적용 대상 : 클라리베인 대상자보다 수술 길이 또는 직경이 큰경우, 남자환자 등",
            "비용 : 600-650만원",
        ],
        thumb: "/images/main/surgery/surgery-02.jpg",
        photo: "/images/main/surgery/surgery-dialog-02.jpg",
        to: "/surgery/flebogrif",
    },
    {
        name_ko: "베나실",
        name_en: "VenaSeal™ Closure System - Cyanoacrylate",
        subject: "역류가 생긴 혈관을 접착 폐쇄하여 역류를 차단하는 방법",
        contents: [
            //
            "수술 시간 : 30-40분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 물리적인 손상이나 열로 인한 손상이 없어 신경 손상이 없고, 통증도 적음",
            "단점 : 이물질에 대한 알러지반응",
            "최적 적용 대상 : 관통정맥이 있는 하지정맥류, \r\n고령환자 등",
            "비용 : 600-650만원",
        ],
        thumb: "/images/main/surgery/surgery-03.jpg",
        photo: "/images/main/surgery/surgery-dialog-03.jpg",
        to: "/surgery/venaseal",
    },
    {
        name_ko: "레이저",
        name_en: "EVLT – Endovenous LASER Tretment",
        subject: "역류가 있는 혈관을 레이저를 통한 고열로  태워서 제거하는 방법",
        contents: [
            //
            "수술 시간 : 30-60분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 20년 이상 사용하여 경험이 많이 축적",
            "단점 : 고열에 의한 화상, 신경손상 가능성이 있음",
            "최적 적용 대상 : 혈관 사이즈가 상대적으로 큰 경우, 정맥 주행이 피부에서 먼 경우 등",
            "비용 : 330-350만원",
        ],
        thumb: "/images/main/surgery/surgery-04.jpg",
        photo: "/images/main/surgery/surgery-dialog-04.jpg",
        to: "/surgery/laser",
    },
    {
        name_ko: "고주파",
        name_en: "RFA : RadioFrequency Ablation",
        subject: "역류가 있는 혈관을 고주파를 통한 고열로 태워서\r\n제거하는 방법으로 레이저와 같은 원리",
        contents: [
            //
            "수술 시간 : 30-60분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 레이저보다 저온을 사용",
            "단점 : 열에 의한 화상, 신경손상 가능성이 있음.",
            "최적 적용 대상 : 혈관 사이즈가 상대적으로 큰 경우, 비열치료가 불가한 경우 등",
            "비용 : 380-420만원",
        ],
        thumb: "/images/main/surgery/surgery-05.jpg",
        photo: "/images/main/surgery/surgery-dialog-05.jpg",
        to: "/surgery/rfa",
    },
    {
        name_ko: "발거술",
        name_en: "Stripping",
        subject: "역류가 있는 혈관을 잡아당겨 물리적으로 제거",
        contents: [
            //
            "수술 시간 : 40-60분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 국민건강보험 적용",
            "단점 : 신경손상 가능성이 있음.",
            "최적 적용 대상 : 혈관이 많이 구불거려 정맥내치료가 불가하거나, 정맥내 혈전이 있는 경우 등",
            "비용 : 280-350만원",
        ],
        thumb: "/images/main/surgery/surgery-06.jpg",
        photo: "/images/main/surgery/surgery-dialog-06.jpg",
        to: "/surgery/stripping",
    },
];

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,

        MainSurgeryDialog,
    },
    data() {
        return {
            academy,
            interact,
            surgery,

            academyOptions: {
                slidesPerView: 1,
                spaceBetween: 50,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                navigation: {
                    nextEl: ".main-slide--academy .swiper-button-next",
                    prevEl: ".main-slide--academy .swiper-button-prev",
                },
                breakpoints: {
                    768: {
                        spaceBetween: 100,
                    },
                },
                thumbs: {
                    swiper: {
                        el: ".thumbs-slide--academy .swiper-container",
                        slideToClickedSlide: true,
                        slidesPerView: "4",
                    },
                },
            },
            academyThumbs: {
                allowTouchMove: true,
                spaceBetween: 8,
                slideToClickedSlide: true,
                slidesPerView: "4",
                breakpoints: {
                    768: {
                        spaceBetween: 16,
                    },
                },
            },

            interactOptions: {
                slidesPerView: 1,
                spaceBetween: 50,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                navigation: {
                    nextEl: ".main-slide--interact .swiper-button-next",
                    prevEl: ".main-slide--interact .swiper-button-prev",
                },
                breakpoints: {
                    768: {
                        spaceBetween: 100,
                    },
                },
                thumbs: {
                    swiper: {
                        el: ".thumbs-slide--interact .swiper-container",
                        slideToClickedSlide: true,
                        slidesPerView: "4",
                    },
                },
            },
            interactThumbs: {
                allowTouchMove: true,
                spaceBetween: 8,
                slideToClickedSlide: true,
                slidesPerView: "4",
                breakpoints: {
                    768: {
                        spaceBetween: 16,
                    },
                },
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.section-bg {
    background-image: url(/images/sub/introduction/team/section-bg.png);
    background-repeat: no-repeat;
    background-size: 150%;
    background-position: center;
}
@media (min-width: 1024px) {
    .section-bg {
        background-size: 100%;
    }
}
.doctor-bg {
    position: relative;
    height: 370px;
    &::after {
        content: "";
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        bottom: calc(-1 * var(--page-section-padding-y-sm) + var(--grid-gutter-xs));
        width: 300px;
        aspect-ratio: 486 / 660;
        background-image: url(/images/sub/introduction/team/team-doctor.png);
        background-position-y: bottom;
        background-size: contain;
    }
}
@media (min-width: 768px) {
    .doctor-bg {
        &::after {
            right: var(--grid-gutter-xs);
            transform: initial;
        }
    }
}
@media (min-width: 1024px) {
    .doctor-bg {
        height: auto;
        &::after {
            width: calc(100% - var(--grid-gutter-xs) * 2);
            right: 50%;
            transform: translateX(50%);
        }
    }
}

@media (min-width: 1200px) {
    .heart-bg {
        position: relative;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
        }
        &::before {
            background-color: #000;
        }
        &::after {
            background-image: url(/images/sub/introduction/team/heart.jpg);
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }
}

.label-img {
    .txt {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}
</style>
