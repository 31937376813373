<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류 비수술" tabActive="혈관경화요법(주사치료)" bg="/images/sub/non-surgical/visual.jpg">
                <template #title>하지정맥류 비수술</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="tit--lg font-weight-regular mb-8px mb-md-16px">혈관경화요법(주사치료)</div>
                        <div class="tit--xs primary--text text--darken-4">Sclerotherapy</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg">경화제를 대상혈관에 직접 주입하여, 혈관벽에 화학적 손상을 줘서 혈관을 제거 하는 방법</u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first page-section--last">
            <v-sheet outlined rounded class="px-16px px-md-28px py-16px py-md-82px">
                <v-row class="row--xs">
                    <v-col cols="12" sm="6">
                        <v-img src="/images/sub/non-surgical/sclerotherapy-01.jpg" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-img src="/images/sub/non-surgical/sclerotherapy-02.jpg" />
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet rounded class="mt-40px mt-md-60px pa-16px pa-md-40px grey lighten-5">
                <v-row class="row--xs">
                    <v-col cols="12" md="6">
                        <u-tit-default class="tit--xs primary--text text--darken-4 mb-10px">혈관경화요법(주사치료)의 가장 좋은 적응증</u-tit-default>
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>
                                    환자의 상태(노인 등), 혈관의 위치, 모양(관통정맥 등)에 따라 근본 치료가 <br class="d-none d-lg-block" />
                                    불가한 경우 대안으로 사용
                                </li>
                                <li>망상정맥 또는 거미양정맥 등에서 미용목적으로 치료</li>
                                <li>근본치료 후 가지정맥 등에서 남은 역류를 정리하는데 사용</li>
                            </ul>
                        </u-txt-default>
                    </v-col>

                    <v-col cols="12" md="6">
                        <u-tit-default class="tit--xs primary--text text--darken-4 mb-10px">혈관경화요법(주사치료)의 금기</u-tit-default>
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>주사약이 전신으로 확산되므로 알러지 등이 있으면 사용 불가</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </v-sheet>

            <div class="pt-16px pt-md-24px">
                <v-row>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">특징 및 장점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>수술(근본치료)을 할 수 없는 경우 또는 위치에서 치료의 대안으로 사용가능</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">제한점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>근본적인 치료법이 아님</li>
                                <li>
                                    복재정맥 자체(main truncal vein)에는 효과가 매우 떨어져, <br class="d-none d-lg-block" />
                                    다른 근본치료가 많이 나온 요즘엔 주치료 방법에서 제외
                                </li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
