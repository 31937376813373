var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-sub-head-basic', _vm._b({
    staticClass: "sub-head--with-tabs",
    attrs: {
      "tabsTag": "u-tabs-slider",
      "tabTag": "v-tab",
      "tabsAttrs": {
        color: 'white',
        class: 'v-tabs--wide-filled'
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'u-sub-head-basic', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }