var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류",
            "tabActive": "하지정맥류치료기준 (조건)",
            "bg": "/images/sub/varicose/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--xs primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("Treatment Standards")]), _c('div', {
    staticClass: "tit--lg font-weight-regular"
  }, [_vm._v("하지정맥류 치료 기준")])])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first"
  }, [_c('u-txt-default', {
    staticClass: "txt--lg txt--dark text-center mb-16px mb-md-24px"
  }, [_vm._v(" 하지정맥류는 대표적인 "), _c('strong', [_vm._v("’양성질환’")]), _vm._v("으로 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v(" "), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("“근본적으로 없애고 싶은 증상이 있는 경우”")]), _vm._v("에 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("진단과 치료를 하는 질병입니다. ")]), _c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('v-sheet', {
    staticClass: "px-8px py-16px text-center",
    staticStyle: {
      "border": "1px solid var(--v-grey-lighten3) !important"
    },
    attrs: {
      "rounded": "sm",
      "color": "grey lighten-5"
    }
  }, [_c('u-txt-default', [_c('div', [_vm._v("양성질환 : 특별한 경우가 아닌 경우 "), _c('br', {
    staticClass: "d-sm-none"
  }), _vm._v(" 사망에 까지 이르지 않는 질환")]), _c('div', {
    staticClass: "mt-4px"
  }, [_vm._v("악성질환 : 치료하지 않는 경우 "), _c('br', {
    staticClass: "d-sm-none"
  }), _vm._v(" 사망에 이를 확률이 높은 질환 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("(예 : 심장병, 암 등)")])])], 1)], 1)], 1)], 1), _c('u-txt-default', {
    staticClass: "txt--lg txt--dark text-center mb-24px mb-md-40px"
  }, [_vm._v(" 하지정맥류의 치료 기준은 "), _c('strong', [_vm._v("다음과 같은 3가지 경우에 ‘수술적 근본치료’의 대상")]), _vm._v("이 됩니다. "), _c('br'), _c('strong', [_vm._v("전제 조건은 ‘초음파 상에서 해당 혈관의 역류가 관찰’")]), _vm._v("되어야 합니다. ")]), _c('v-card', {
    staticClass: "rounded-xl rounded-md-pill px-24px px-md-130px py-40px py-md-32px mb-24px mb-md-40px",
    attrs: {
      "outlined": "",
      "flat": "",
      "color": "primary"
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "d-flex justify-center align-center mb-8px mb-md-16px",
    attrs: {
      "flat": "",
      "height": "44",
      "rounded": "pill",
      "color": "primary"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--xs"
  }, [_vm._v(" 필수 ")])], 1), _c('u-txt-default', {
    staticClass: "font-weight-bold secondary--text text-center"
  }, [_c('p', [_vm._v("01. 혈전이 있거나, 혈전에 의한 정맥염이 있는 경우")]), _c('p', [_vm._v("02. 피부병변(습진, 착색, 궤양, 지방피부경화증 등)이 동반되는 경우")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "d-flex justify-center align-center mb-8px mb-md-32px",
    attrs: {
      "flat": "",
      "height": "44",
      "rounded": "pill",
      "color": "primary"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--xs"
  }, [_vm._v("상대적")])], 1), _c('u-txt-default', {
    staticClass: "font-weight-bold secondary--text text-center"
  }, [_vm._v(" 03. 없애고 싶은 불편한 증상이 있는 경우 ")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/varicose/standard-01.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/varicose/standard-02.jpg"
    }
  })], 1)], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--last grey lighten-5"
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary font-weight-regular"
  }, [_vm._v("하지정맥류 수술적 치료의 적응증")])], 1), _c('v-sheet', {
    staticClass: "pa-24px pa-md-40px",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "",
      "order-md": "2"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm mb-24px mb-md-40px"
  }, [_vm._v("The indicaiton of surgical treatment for varicose veins")]), _c('u-txt-default', [_c('div', {
    staticClass: "mb-16px mb-md-24px"
  }, [_vm._v(" Surgical treatment for varicose veins is usually considered when other, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" less invasive treatments have failed or are not suitable. The indications for considering surgical "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" intervention include: ")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('strong', [_vm._v("1.Symptomatic Varicose Veins:")]), _vm._v(" Persistent symptoms such as pain, swelling, leg heaviness, and cramping that significantly affect quality of life.")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('strong', [_vm._v("2.Complications:")]), _vm._v(" Development of complications from varicose veins, such as skin changes (e.g., eczema, pigmentation), lipodermatosclerosis (hardening of skin and fat beneath the skin), venous ulcers, or bleeding from the varicose veins.")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('strong', [_vm._v("3.Venous Insufficiency:")]), _vm._v(" Severe chronic venous insufficiency, which can lead to more serious health issues if not treated.")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('strong', [_vm._v("4.Thrombophlebitis:")]), _vm._v(" Recurrent or persistent superficial thrombophlebitis, which is an inflammatory process causing a blood clot to form and block one or more veins, usually in the legs.")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('strong', [_vm._v("5.Aesthetic Concerns:")]), _vm._v(" In some cases, patients might seek surgical treatment for varicose veins primarily due to aesthetic concerns, although this is typically considered only after evaluating the potential benefits and risks")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "792",
      "src": "/images/sub/varicose/adaptation.jpg"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }