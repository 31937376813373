var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류",
            "tabActive": "하지정맥류란?",
            "bg": "/images/sub/varicose/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--xs primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("Varicose vein")]), _c('div', {
    staticClass: "tit--lg font-weight-regular"
  }, [_vm._v("하지정맥류란?")])])], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first page-section--last"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-weight-regular mb-16px mb-24px"
  }, [_c('strong', [_vm._v("하지정맥류")]), _vm._v("는 "), _c('strong', [_vm._v("대표적인 혈관질환")]), _vm._v("입니다.")]), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 다리 정맥 내부에서 혈액의 역류를 막아주는 판막이 손상되어"), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 혈액이 심장으로 가지 못하고 정체되거나 역류하면서 발생하는 대표적인 혈관 질환입니다. ")])], 1), _c('v-sheet', {
    staticClass: "px-16px py-24px py-md-40px",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "src": "/images/sub/varicose/varicose-vein.png",
      "max-width": "778"
    }
  })], 1), _c('div', {
    staticClass: "mt-60px mt-md-80px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "rounded": "pill",
      "color": "grey lighten-5 number-card font-weight-bold font-secondary py-12px pl-86px mb-24px mb-md-40px"
    }
  }, [_c('div', [_c('v-avatar', {
    staticClass: "number font-size-28",
    attrs: {
      "size": "60",
      "color": "primary"
    }
  }, [_vm._v("01")])], 1), _c('u-tit-default', {
    staticClass: "tit--sm"
  }, [_vm._v("선천적요인")])], 1), _c('u-txt-default', {
    staticClass: "txt--lg px-24px pl-md-40px pr-md-0"
  }, [_c('div', [_vm._v(" 선천적으로 다리 정맥의 판막이 약한 것이 가장 중요한 원인입니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 같은 환경이라도 선천적 요인이 있는 사람은 쉽게 판막이 손상됩니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 따라서 하지정맥류는 '가족력'이 매우 강한 질환입니다. ")]), _c('div', {
    staticClass: "mt-8px mt-md-16px grey--text text--lighten-1"
  }, [_vm._v(" 부모 중 한명이 환자일 때 아들은 20-30%, "), _c('br', {
    staticClass: "d-sm-none"
  }), _vm._v("딸은 60-70%에서 발생 "), _c('br'), _vm._v(" 부모 둘다 환자인 경우 아들, 딸 중 90% 이상에서 발생 ")]), _c('v-img', {
    staticClass: "mt-24px mt-md-40px rounded",
    attrs: {
      "src": "/images/sub/varicose/cause-01.jpg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "rounded": "pill",
      "color": "grey lighten-5 number-card font-weight-bold font-secondary py-12px pl-86px mb-24px mb-md-40px"
    }
  }, [_c('div', [_c('v-avatar', {
    staticClass: "number font-size-28",
    attrs: {
      "size": "60",
      "color": "primary"
    }
  }, [_vm._v("02")])], 1), _c('u-tit-default', {
    staticClass: "tit--sm"
  }, [_vm._v("후천적요인")])], 1), _c('u-txt-default', {
    staticClass: "txt--lg px-24px pl-md-40px pr-md-0"
  }, [_vm._v(" 오래 서있는 직업, 고령, 임신, 비만, 피임약, 호르몬치료, 여성 등이 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 판막 손상의 고위험 인자입니다. "), _c('v-img', {
    staticClass: "mt-24px mt-md-142px rounded",
    attrs: {
      "src": "/images/sub/varicose/cause-02.png"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }