var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-header-basic', {
    staticClass: "header--lnb-all"
  }, [_c('u-header-body-basic', {
    staticClass: "d-none d-xl-block",
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xxs",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('logo-primary', {
          class: _vm.logoClass
        }, [_vm._v("편안하지흉부외과")])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-card', {
          staticClass: "d-flex align-center px-18px",
          attrs: {
            "height": "34",
            "rounded": "pill",
            "color": "secondary"
          }
        }, [_c('v-img', {
          staticClass: "mr-6px",
          attrs: {
            "eager": "",
            "max-width": "16",
            "src": "/images/icon/icon-phone.svg"
          }
        }), _c('u-tit-default', {
          staticClass: "tit--xs"
        }, [_vm._v("02-6424-7979")])], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('u-gnb-basic', {
          staticClass: "text-center",
          attrs: {
            "gnbName": "gnb",
            "lnbTag": "u-lnb-all-opacity-black"
          }
        })];
      },
      proxy: true
    }])
  }), _c('sitemap-primary')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }