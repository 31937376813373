<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="편안하지흉부외과" tabActive="진료시간/오시는 길" bg="/images/sub/introduction/visual.jpg">
                <template #title>편안하지흉부외과</template>
            </sub-head-primary>
        </template>

        <u-tit-wrap-default class="tit-wrap--lg text-center">
            <u-tit-default class="font-secondary">
                <div class="tit--xs primary--text text--darken-4 mb-8px mb-md-16px">Clinic hours &amp; Location</div>
                <div class="tit--lg font-weight-regular">진료시간 &amp; 오시는 길</div>
            </u-tit-default>
        </u-tit-wrap-default>

        <page-section-primary class="page-section--sm page-section--first page-section--last">
            <v-row class="row--xs">
                <v-col cols="12" lg="6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.6854712576!2d127.10040047642867!3d37.51533582706148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca50b6af4ec7b%3A0x3fe9b6c3d5770a8e!2z7Y647JWI7ZWY7KeA7Z2J67aA7Jm46rO87J2Y7JuQ!5e0!3m2!1sko!2skr!4v1712993619826!5m2!1sko!2skr" width="100%" height="218" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="h-md-100 rounded" />
                </v-col>
                <v-col cols="12" lg="6">
                    <v-row class="row--xs">
                        <v-col cols="12">
                            <v-sheet rounded color="secondary" class="pa-16px pa-md-40px">
                                <u-tit-default class="font-secondary mb-8px mb-md-16px">진료안내</u-tit-default>
                                <v-divider />
                                <u-txt-default class="txt--lg font-size-md-18 txt--light">
                                    <ul class="pt-8px pt-md-16px">
                                        <li>
                                            <v-row no-gutters>
                                                <v-col cols="4" sm="3" md="auto">
                                                    <div class="w-md-130px">
                                                        <v-row align="center" no-gutters>
                                                            <v-col cols="auto">
                                                                <v-img max-width="20" src="/images/icon/icon-phone-primary.svg" class="mr-8px" />
                                                            </v-col>
                                                            <v-col>대표전화</v-col>
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                                <v-col>02-6424-7979</v-col>
                                            </v-row>
                                        </li>
                                        <li class="pt-24px pt-md-32px">
                                            <v-row no-gutters>
                                                <v-col cols="4" sm="3" md="auto">
                                                    <div class="w-md-130px">
                                                        <v-row align="center" no-gutters>
                                                            <v-col cols="auto">
                                                                <v-img max-width="20" src="/images/icon/icon-clock.svg" class="mr-8px" />
                                                            </v-col>
                                                            <v-col>진료시간</v-col>
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                                <v-col>
                                                    <ul>
                                                        <li>월요일-금요일 <br class="d-md-none" />AM 09 : 00 - PM 06 : 00</li>
                                                        <li class="pt-4px">토요일 <br class="d-md-none" />AM 09 : 00 - PM 04 : 00 <br />(토요일은 점심시간이 없습니다)</li>
                                                        <li class="pt-4px">점심시간 <br class="d-md-none" />PM 01 : 00 - PM 02 : 00</li>
                                                        <li>일요일 / 공휴일 휴진</li>
                                                    </ul>
                                                </v-col>
                                            </v-row>
                                        </li>
                                    </ul>
                                </u-txt-default>
                            </v-sheet>
                        </v-col>
                        <v-col cols="12">
                            <v-sheet rounded color="grey lighten-5" class="pa-16px pa-md-40px">
                                <u-tit-default class="font-secondary mb-8px mb-md-16px">오시는 길</u-tit-default>
                                <v-divider style="border-color: #000" />
                                <u-txt-default class="txt--lg font-size-md-18">
                                    <ul class="pt-8px pt-md-16px">
                                        <li>
                                            <v-row no-gutters>
                                                <v-col cols="4" sm="3" md="auto">
                                                    <div class="w-md-130px">
                                                        <v-row align="center" no-gutters>
                                                            <v-col cols="auto">
                                                                <v-img max-width="20" src="/images/icon/icon-map.svg" class="mr-8px" />
                                                            </v-col>
                                                            <v-col>위치</v-col>
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                                <v-col>서울시 송파구 올림픽로 35다길 42 루터회관 601호</v-col>
                                            </v-row>
                                        </li>
                                        <li class="pt-16px pt-md-20px">
                                            <v-row no-gutters>
                                                <v-col cols="4" sm="3" md="auto">
                                                    <div class="w-md-130px">
                                                        <v-row align="center" no-gutters>
                                                            <v-col cols="auto">
                                                                <v-img max-width="20" src="/images/icon/icon-bus.svg" class="mr-8px" />
                                                            </v-col>
                                                            <v-col>교통버스</v-col>
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                                <v-col>
                                                    <ul>
                                                        <li>잠실역 / 잠실역 중앙차로에서 하차</li>
                                                        <li class="pt-4px">
                                                            <ul class="dot-list dot-list--sm">
                                                                <li>30-3, 30-5, 31, 32, 35, 101, 119, 2000-2</li>
                                                                <li class="mt-4px">301, 302, 303, 320, 341, 345, 360, N13, N32, N34</li>
                                                                <li class="mt-4px">2311, 2412, 2415, 3215, 3216, 3217, 3313, 3314, 3315, 3317, 3319, 3411, 3413, 3414, 4319</li>
                                                                <li class="mt-4px">강남05</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </v-col>
                                            </v-row>
                                        </li>
                                        <li class="pt-16px pt-md-20px">
                                            <v-row no-gutters>
                                                <v-col cols="4" sm="3" md="auto">
                                                    <div class="w-md-130px">
                                                        <v-row align="center" no-gutters>
                                                            <v-col cols="auto">
                                                                <v-img max-width="20" src="/images/icon/icon-subway.svg" class="mr-8px" />
                                                            </v-col>
                                                            <v-col>지하철</v-col>
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                                <v-col><span class="line-2">2</span>, <span class="line-8">8</span>호선 잠실역(송파구청) 8, 9번 출구 사이, 루터회관 6층</v-col>
                                            </v-row>
                                        </li>
                                        <li class="pt-16px pt-md-20px">
                                            <v-row no-gutters>
                                                <v-col cols="4" sm="3" md="auto">
                                                    <div class="w-md-130px">
                                                        <v-row align="center" no-gutters>
                                                            <v-col cols="auto">
                                                                <v-img max-width="20" src="/images/icon/icon-car.svg" class="mr-8px" />
                                                            </v-col>
                                                            <v-col>승용차</v-col>
                                                        </v-row>
                                                    </div>
                                                </v-col>
                                                <v-col>
                                                    네비게이션에 '루터회관' 입력 <br />
                                                    루터회관 주차장 <br />
                                                    (지하 2층부터 지하 5층까지 무료이용 가능)
                                                </v-col>
                                            </v-row>
                                        </li>
                                    </ul>
                                </u-txt-default>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <iframe width="100%" src="https://www.youtube.com/embed/9B2kklMoRYI?si=gkkHOk4NYbBwqbU8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="rounded mt-24px mt-md-60px" />
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
iframe {
    aspect-ratio: 16 / 9;
}
</style>
