<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="편안하지흉부외과" tabActive="편안하지 소개" bg="/images/sub/introduction/visual.jpg">
                <template #title>편안하지흉부외과</template>
            </sub-head-primary>
        </template>

        <u-tit-wrap-default class="tit-wrap--lg text-center">
            <u-tit-default class="font-secondary">
                <div class="tit--xs primary--text text--darken-4 mb-8px mb-md-16px">Introduction</div>
                <div class="tit--lg font-weight-regular">편안하지 소개</div>
            </u-tit-default>
        </u-tit-wrap-default>

        <page-section-primary class="page-section--sm page-section--first">
            <div class="text-center">
                <u-tit-default class="font-weight-regular mb-16px mb-24px">하지정맥류, 해답은 <br class="d-md-none" /><strong class="primary--text">편안하지흉부외과</strong>입니다.</u-tit-default>
                <u-txt-default class="txt--lg">
                    편안하지흉부외과는 심장혈관외과 전문의의 풍부한 경력과 믿을 수 있는 진료 환경으로 만족스러운 하지정맥류 치료 결과를 보장합니다.<br class="d-none d-lg-block" />
                    한결같은 마음으로 환자만족을 위해 최선을 다할 것을 약속드립니다.
                </u-txt-default>
            </div>
            <v-img max-width="328" src="/images/sub/introduction/introduction-main-mobile.png" class="d-md-none mx-auto mt-24px" />
            <v-img src="/images/sub/introduction/introduction-main.png" class="d-none d-md-block mt-60px" />
        </page-section-primary>

        <page-section-primary class="page-section--sm primary lighten-4 secondary--text text--darken-4">
            <v-row class="row--xs">
                <v-col cols="12" lg="7">
                    <u-tit-wrap-default>
                        <u-tit-default class="font-secondary font-weight-regular text-center text-md-start">
                            <strong>편안하지흉부외과</strong>는 <br />
                            이렇게 <strong class="primary--text text--darken-4">진료</strong>합니다.
                        </u-tit-default>
                    </u-tit-wrap-default>

                    <u-tit-default class="font-secondary font-weight-regular mb-16px mb-md-24 text-center text-md-start">
                        <div class="tit--xs mb-8px mb-md-16px">환자에게 가장 안전하고 효과적인 <br class="d-md-none" />치료를 제공하기 위해서는</div>
                        <div class="tit--sm">의학의 법과 원칙인 <br class="d-md-none" /><strong>교과서</strong>에 따라 치료해야 한다.</div>
                    </u-tit-default>

                    <u-txt-default class="txt--lg line-height-17">
                        우리나라 최고의 혈관수술 병원인 서울아산병원에서 10년 넘게 정식으로 수련 받고 <br class="d-none d-xl-block" />
                        근무하면서 배우고 익힌 환자진료의 이념(理念)입니다. 현재 교과서(guidelines)<br class="d-none d-xl-block" />
                        에서는 <strong class="grey--text text--darken-3">클라리베인, 베나실, 고주파와 같은 ‘정맥내 요법’ 을 하지정맥의 표준치료</strong>로 <br class="d-none d-xl-block" />
                        제시하고 있습니다.
                    </u-txt-default>

                    <div class="mt-24px mt-md-40px">
                        <v-row class="row--xs justify-center justify-md-start">
                            <v-col cols="12" class="d-md-none">
                                <v-card color="transparent" tile elevation="4">
                                    <v-img src="/images/sub/introduction/guideline-01-mobile.jpg"></v-img>
                                </v-card>
                                <u-txt-default class="txt--lg text-center mt-8px mt-md-16px">영국 하지정맥류 진료지침</u-txt-default>
                            </v-col>
                            <v-col cols="auto" class="d-none d-md-block">
                                <v-card color="transparent" tile elevation="4">
                                    <v-img src="/images/sub/introduction/guideline-01.jpg" class="guideline-image-01" />
                                </v-card>
                                <u-txt-default class="txt--lg text-center mt-8px mt-md-16px">영국 하지정맥류 진료지침</u-txt-default>
                            </v-col>
                            <v-col cols="12" class="d-md-none">
                                <v-card tile elevation="4">
                                    <v-img src="/images/sub/introduction/guideline-02-mobile.jpg" />
                                </v-card>
                                <u-txt-default class="txt--lg text-center mt-8px mt-md-16px">미국 하지정맥류 진료지침</u-txt-default>
                            </v-col>
                            <v-col cols="auto" class="d-none d-md-block">
                                <v-card tile elevation="4">
                                    <v-img src="/images/sub/introduction/guideline-02.jpg" class="guideline-image-02" />
                                </v-card>
                                <u-txt-default class="txt--lg text-center mt-8px mt-md-16px">미국 하지정맥류 진료지침</u-txt-default>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12" lg="5" class="doctor-bg" />
            </v-row>
        </page-section-primary>

        <page-section-primary>
            <u-tit-wrap-default class="text-center">
                <u-tit-default class="font-weight-regular mb-16px mb-24px">끊임없는 노력, <strong class="primary--text">뛰어난 의료서비스</strong></u-tit-default>
                <u-txt-default class="txt--lg grey--text text--darken-3">
                    편안하지흉부외과에서는 하지정맥류의 진단 및 치료의 원칙을 끊임없이 공부하여<br class="d-none d-sm-block" />
                    환자에게 최선의 의료서비스를 제공할 수 있도록 노력합니다.
                </u-txt-default>
            </u-tit-wrap-default>

            <main-story-slide />

            <v-sheet rounded color="grey lighten-5 mt-40px mt-md-60px pa-16px pa-md-40px">
                <v-row class="row--xs">
                    <v-col cols="6" md="3">
                        <v-img src="/images/sub/introduction/Certified-01.png" class="border-x border-y"/>
                        <u-txt-default class="txt--sm text-center mt-6px mt-md-8px"
                            >이승철 원장은 <br class="d-md-none" /><strong>베나실(VenaSeal)의 <br />안정적인 시술자</strong>입니다.</u-txt-default
                        >
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-img src="/images/sub/introduction/Certified-02.png" class="border-x border-y" />
                        <u-txt-default class="txt--sm text-center mt-6px mt-md-8px"
                            >RFA(고주파) 수술의 <br class="d-md-none" />
                            <strong>교육자문위원</strong>으로 <br />
                            위촉된 이승철 원장</u-txt-default
                        >
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-img src="/images/sub/introduction/Certified-03.png" />
                        <u-txt-default class="txt--sm text-center mt-6px mt-md-8px">편안하지흉부외과 <br /><strong>베나실 교육기관 선정</strong></u-txt-default>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-img src="/images/sub/introduction/Certified-04.png" />
                        <u-txt-default class="txt--sm text-center mt-6px mt-md-8px"
                            >편안하지흉부외과 <br />
                            <strong>고주파 교육기관 선정</strong></u-txt-default
                        >
                    </v-col>
                </v-row>
            </v-sheet>
        </page-section-primary>

        <page-section-primary class="page-section--sm page-section--last grey lighten-5 grey--text text--darken-4">
            <u-tit-wrap-default class="text-center">
                <u-tit-default class="font-weight-regular mb-16px mb-24px">편안하지흉부외과는 <br class="d-md-none" /><strong class="primary--text">환자를 최우선으로</strong> 생각합니다.</u-tit-default>
                <u-txt-default class="txt--lg grey--text text--darken-3"> 검증된 하지정맥류 치료를 통해 환자들이 안심하고, <br class="d-md-none" />치료 후 건강하게 웃을 수 있는 병원을 꿈꿉니다. </u-txt-default>
            </u-tit-wrap-default>

            <div class="marks-wrap py-24px py-md-40px">
                <v-row justify="center" class="row--xs">
                    <v-col cols="4">
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" lg="auto">
                                <v-img max-width="160" src="/images/sub/introduction/mark-01.png" class="mx-auto" />
                            </v-col>
                            <v-col cols="12" lg="" class="text-center text-lg-start">
                                <u-tit-default class="tit--sm">전문성</u-tit-default>
                                <u-txt-default class="txt--lg">
                                    흉부심장혈관외과 <br class="d-none d-sm-block" />
                                    전문의 진료
                                </u-txt-default>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4">
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" lg="auto">
                                <v-img max-width="160" src="/images/sub/introduction/mark-02.png" class="mx-auto" />
                            </v-col>
                            <v-col cols="12" lg="" class="text-center text-lg-start">
                                <u-tit-default class="tit--sm">실력</u-tit-default>
                                <u-txt-default class="txt--lg">
                                    압도적인 수술경험, <br class="d-none d-sm-block" />
                                    하지정맥류 관련 <br class="d-none d-sm-block" />
                                    모든 수술 가능
                                </u-txt-default>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4">
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" lg="auto">
                                <v-img max-width="160" src="/images/sub/introduction/mark-03.png" class="mx-auto" />
                            </v-col>
                            <v-col cols="12" lg="" class="text-center text-lg-start">
                                <u-tit-default class="tit--sm">결과</u-tit-default>
                                <u-txt-default class="txt--lg">
                                    환자 만족을 위한 <br class="d-none d-sm-block" />
                                    철저한 사후관리
                                </u-txt-default>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>

            <ul class="introduction-list">
                <li>
                    <v-row class="row--xxs">
                        <v-col cols="auto">
                            <u-tit-default class="tit--sm font-weight-regular font-secondary primary--text text--darken-4">01</u-tit-default>
                        </v-col>
                        <v-col class="pr-20px pr-md-0">
                            <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-16px">환자에게 가장 편리하고 효율적인 치료를 제공하기 위한 원스톱 시스템</u-tit-default>
                            <u-txt-default class="txt--lg">
                                <strong>내원 당일에 혈관 초음파를 이용한 진단</strong>부터 환자분이 원하시는 경우 <strong>증상에 맞는 치료 및 수술</strong>까지 이루어지는 <strong>원스톱 시스템</strong>을 갖추고 있습니다. <br class="d-none d-xl-block" />
                                하지정맥류 수술 후에도 실밥제거, 소독 등의 <strong>번거로운 외래 진료가 필요하지 않은 수술법을 적용</strong>하여 환자분들의 불편을 최소화하고 있습니다.
                            </u-txt-default>
                        </v-col>
                    </v-row>
                </li>
                <li>
                    <v-row class="row--xxs">
                        <v-col cols="auto">
                            <u-tit-default class="tit--sm font-weight-regular font-secondary primary--text text--darken-4">02</u-tit-default>
                        </v-col>
                        <v-col class="pr-20px pr-md-0">
                            <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-16px">교차감염을 방지하여 환자의 안전을 도모하는 1인 1기구 사용 및 1회용 사용 원칙</u-tit-default>
                            <u-txt-default class="txt--lg">
                                <ul class="dot-list dot-list--sm">
                                    <li>하지정맥 수술에 사용되는 모든 장비 및 소모품 1인 1기구 사용 및 1회용 사용</li>
                                    <li>수술 장비에 사용되는 파이버(Fiber) 1회 사용 후 교체</li>
                                    <li>수술 기구는 가장 최신의 소독 장비인 플라즈마 소독기로 완벽한 멸균 소독</li>
                                    <li>편안하지흉부외과는 표준수술장비를 납품하는 토탈베인시스템과 함께 합니다.</li>
                                </ul>
                            </u-txt-default>
                        </v-col>
                    </v-row>
                </li>
                <li>
                    <v-row class="row--xxs">
                        <v-col cols="auto">
                            <u-tit-default class="tit--sm font-weight-regular font-secondary primary--text text--darken-4">03</u-tit-default>
                        </v-col>
                        <v-col class="pr-20px pr-md-0">
                            <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-16px">수술 후에도 오롯이 나 혼자 편안하게 회복할 수 있는 1인 집중 회복실</u-tit-default>
                            <u-txt-default class="txt--lg"> 하지정맥류 수술 후에는 <strong>1인 집중 회복실에서 전문 간호사의 숙련된 의료 서비스</strong>를 받을 수 있습니다. </u-txt-default>
                        </v-col>
                    </v-row>
                </li>
            </ul>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainStorySlide from "@/components/client/main/story/main-story-slide.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,

        MainStorySlide,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.doctor-bg {
    position: relative;
    height: 300px;
    &::after {
        content: "";
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        bottom: calc(-1 * var(--page-section-padding-y-sm) + var(--grid-gutter-xs));
        width: 200px;
        aspect-ratio: 486 / 794;
        background-image: url(/images/sub/introduction/introduction-doctor.png);
        background-position-y: bottom;
        background-size: contain;
    }
}
@media (min-width: 768px) {
    .doctor-bg {
        height: 100px;
        &::after {
            right: 0;
            transform: initial;
        }
    }
}
@media (min-width: 1024px) {
    .doctor-bg {
        height: auto;
        &::after {
            width: 100%;
            right: 50%;
            transform: translateX(50%);
        }
    }
}
.guideline-image {
    &-01 {
        width: 244px;
    }
    &-02 {
        width: 182px;
    }
    @media (min-width: 768px) {
        &-01,
        &-02 {
            width: auto;
        }
    }
}
.marks-wrap {
    border-top: 1px solid var(--v-primary-darken4);
    border-bottom: 1px solid var(--v-primary-darken4);
}

.introduction-list {
    > li {
        padding-top: 24px;
    }
}
@media (min-width: 768px) {
    .introduction-list {
        > li {
            padding-top: 60px;
        }
    }
}
</style>
