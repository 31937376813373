var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류 수술",
            "tabActive": "발거술",
            "bg": "/images/sub/surgery/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류 수술")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--lg font-weight-regular mb-8px mb-md-16px"
  }, [_vm._v("발거술")]), _c('div', {
    staticClass: "tit--xs primary--text text--darken-4"
  }, [_vm._v("High Iigation and Stripping")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v("문제가 되는 혈관을 수술적 방법을 통해 제거하여 없애는 방법입니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 혈관이 심하게 울퉁불퉁하거나 혈전이 있어 혈관 전용 레이저 수술이 부적합한 경우에 시행됩니다.")])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first"
  }, [_c('v-sheet', {
    staticClass: "pa-20px py-md-68px",
    attrs: {
      "rounded": "",
      "outlined": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "ml-auto",
    attrs: {
      "max-width": "508",
      "src": "/images/sub/surgery/stripping-01.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "mr-auto",
    attrs: {
      "max-width": "508",
      "src": "/images/sub/surgery/stripping-02.jpg"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "pt-40px pt-md-60px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("특징 및 장점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v(" 하지정맥류가 많이 진행되어 혈관이 매우 구불구불한 경우 등 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" '정맥내요법'이 불가능한 경우 적용 가능 ")]), _c('li', [_vm._v("우리나라의 경우 '국민건강보험' 적용 가능")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("단점 및 부작용")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v(" 마취, 통증, 흉터, 일상 생활 복귀, 재발 등 모든 면에서 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" '정맥내치료요법'에 비해 불리 ")]), _c('li', [_vm._v("미국 유럽 등 선진국가에서 이미 우선 치료 방법에서 제외")])])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }