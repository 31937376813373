<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-secondary itemTitle="치료비용 안내" bg="/images/sub/cost/visual.jpg">
                <template #title>치료비용 안내</template>
            </sub-head-secondary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-default class="font-secondary">
                    <div class="tit--xs primary--text text--darken-4 mb-8px mb-md-16px">Cost guide</div>
                    <div class="tit--lg font-weight-regular">편안하지 수술비 안내</div>
                </u-tit-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first page-section--last">
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="font-weight-regular">
                            편안하지흉부외과는 <br class="d-md-none"><strong class="primary--text text--darken-4">‘수술비용’</strong> 및 <strong class="primary--text text--darken-4">‘수술종류’</strong>에 <br class="d-none d-lg-block" />
                            환자의 상태를 맞추지 않습니다.
                        </div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg">편안하지흉부외과는 모든 수술을 다 시행하고, 또 수술 종류에 따른 수술비용의 차이가 없습니다.</u-txt-default>
            </u-tit-wrap-default>

            <v-sheet rounded color="primary lighten-5" class="pa-24px pa-md-60px grey--text text--darken-4">
                <u-tit-default class="tit--sm text-center line-height-15"> 1. 수술은 크게 3그룹 (A 발거술, B 열치료(고주파/레이저), C 비열치료(클라리베인/베나실/플레보그립))으로 나뉘고 각 그룹별 수술비용의 차이는 있지만, 그룹내 수술 종류에 따른 수술 비용의 차이는 없습니다.</u-tit-default>

                <v-card flat outlined color="primary" max-width="560" class="rounded-xl rounded-md-pill font-secondary pa-16px pa-md-24px my-24px my-md-40px mx-auto">
                    <v-row justify="center" class="row--xxs">
                        <v-col cols="6" sm="4">
                            <v-sheet rounded="pill" color="primary" class="pa-12px pa-md-16px font-secondary">
                                <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
                                    <u-txt-default class="text-center txt--light line-height-1">
                                        <div class="mb-4px mb-md-8px"><strong>A 발거술</strong></div>
                                        <div class="txt--sm">발거술</div>
                                    </u-txt-default>
                                </v-responsive>
                            </v-sheet>
                        </v-col>
                        <v-col cols="6" sm="4">
                            <v-sheet rounded="pill" color="primary" class="pa-12px pa-md-16px">
                                <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
                                    <u-txt-default class="text-center txt--light line-height-1">
                                        <div class="mb-4px mb-md-8px"><strong>B 열치료</strong></div>
                                        <div class="txt--sm">고주파 / 레이저</div>
                                    </u-txt-default>
                                </v-responsive>
                            </v-sheet>
                        </v-col>
                        <v-col cols="6" sm="4">
                            <v-sheet rounded="pill" color="primary" class="pa-12px pa-md-16px">
                                <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
                                    <u-txt-default class="text-center txt--light line-height-1">
                                        <div class="mb-4px mb-md-8px"><strong>C 비열치료</strong></div>
                                        <div class="txt--sm">클라리베인 / 베나실 / 플레보그립</div>
                                    </u-txt-default>
                                </v-responsive>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-card>

                <u-tit-default class="tit--sm font-weight-regular text-center">
                    <div class="mb-8px mb-md-16px">즉, <strong class="primary--text text--darken-4">환자의 상태에 따라 최선의 수술방법을 선택</strong>합니다.</div>
                    <div class="primary--text text--darken-4">수술비용 또는 원장의 편의에 따라 수술방법을 결정하지 않습니다.</div>
                </u-tit-default>
            </v-sheet>
            <v-sheet rounded color="primary lighten-5" class="mt-16px mt-md-24px pa-24px pa-md-60px grey--text text--darken-4">
                <u-tit-default class="tit--sm text-center line-height-15">2. 수술 혈관 개수에 따른 수술 비용의 차이가 없습니다. </u-tit-default>
                <u-txt-default class="text-center mt-24px mt-md-40px">
                    <div class="txt--lg mb-8px mb-md-16px">‘줄기(뿌리) 당 얼마’의 식으로 수술비용을 계산하지 않습니다.</div>
                    <div class="txt--xl">
                        <strong>따라서, <span class="primary--text">꼭 수술이 필요한 혈관</span>에만 수술을 시행합니다.</strong>
                    </div>
                </u-txt-default>
            </v-sheet>

            <v-sheet></v-sheet>

            <main-cost-table mobile class="d-md-none pt-40px" />
            <main-cost-table class="d-none d-md-block pt-md-60px" />

            <v-sheet id="non-benefit" class="mt-60px mt-md-80px">
                <u-tit-wrap-default class="text-center">
                    <u-tit-default class="font-secondary font-weight-regular">편안하지흉부외과 비급여 세부항목 고시</u-tit-default>
                </u-tit-wrap-default>
                <v-divider style="border-color: var(--v-grey-darken)" />

                <div class="non-benefit-table">
                    <v-img src="/images/sub/cost/cost-non-benefit.png" />
                </div>
            </v-sheet>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadSecondary from "@/sets/parents/pages/sub-head-secondary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainCostTable from "@/components/client/main/cost/main-cost-table.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadSecondary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,

        MainCostTable,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.non-benefit-table {
    overflow-x: auto;
    .v-image {
        width: 1200px !important;
        max-width: 1200px !important;
    }
}
</style>
