var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류 수술",
            "tabActive": "고주파",
            "bg": "/images/sub/surgery/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류 수술")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--lg font-weight-regular mb-8px mb-md-16px"
  }, [_vm._v("고주파 수술")]), _c('div', {
    staticClass: "tit--xs primary--text text--darken-4"
  }, [_vm._v("RFA : RadioFrequency Ablation")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 고주파가 발생하는 카테터를 정맥 내부에 삽입한 후 고주파 열로 정맥을 위축시켜 치료하는 방법입니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 수술과정은 레이저 수술과 비슷하지만 수술 후 통증이나 멍드는 현상이 훨씬 적어 회복이 빠릅니다. ")])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first page-section--last"
  }, [_c('v-sheet', {
    staticClass: "pa-20px py-md-68px",
    attrs: {
      "rounded": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "588",
      "src": "/images/sub/surgery/rfa.jpg"
    }
  })], 1), _c('v-sheet', {
    staticClass: "primary lighten-5 mt-40px mt-md-60px pa-24px px-lg-80px py-lg-60px",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "528",
      "src": "/images/sub/surgery/rfa-image-01.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "488",
      "src": "/images/sub/surgery/rfa-image-02.jpg"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "pt-40px pt-md-60px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("특징 및 장점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("레이저치료의 장점은 유지하면서 단점을 보완한 방법")]), _c('li', [_vm._v(" 방출되는 열이 120도로, 레이저보다 현저히 낮아 열에 의한 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 부작용 가능성이 더 낮음 ")]), _c('li', [_vm._v("레이저에 비해 수술 후 통증이 적음")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("단점 및 부작용")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("압박스타킹 최소 2주간 착용")]), _c('li', [_vm._v("통증 및 신경손상 가능 (레이저보다 현저히 낮지만 가능성이 있음)")]), _c('li', [_vm._v("일시적 정맥염")]), _c('li', [_vm._v("심부정맥혈전증 (매우 희박)")])])])], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "grey lighten-5 mt-60px mt-md-80px pa-16px py-md-60px",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto border-x border-y",
    attrs: {
      "max-width": "322",
      "src": "/images/sub/introduction/Certified-02.png"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--sm mt-10px text-center"
  }, [_vm._v(" RFA(고주파) 수술의 "), _c('br'), _c('strong', [_vm._v("교육자문위원")]), _vm._v("으로 "), _c('br'), _vm._v(" 위촉된 이승철 원장 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "320",
      "src": "/images/sub/introduction/Certified-04.png"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--sm font-size-md-18 mt-10px text-center"
  }, [_vm._v("편안하지흉부외과 "), _c('br'), _c('strong', [_vm._v("고주파 교육기관 선정")])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }