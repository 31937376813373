var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "편안하지흉부외과",
            "tabActive": "편안하지 소개",
            "bg": "/images/sub/introduction/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("편안하지흉부외과")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--xs primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("Introduction")]), _c('div', {
    staticClass: "tit--lg font-weight-regular"
  }, [_vm._v("편안하지 소개")])])], 1), _c('page-section-primary', {
    staticClass: "page-section--sm page-section--first"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-weight-regular mb-16px mb-24px"
  }, [_vm._v("하지정맥류, 해답은 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("편안하지흉부외과")]), _vm._v("입니다.")]), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 편안하지흉부외과는 심장혈관외과 전문의의 풍부한 경력과 믿을 수 있는 진료 환경으로 만족스러운 하지정맥류 치료 결과를 보장합니다."), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 한결같은 마음으로 환자만족을 위해 최선을 다할 것을 약속드립니다. ")])], 1), _c('v-img', {
    staticClass: "d-md-none mx-auto mt-24px",
    attrs: {
      "max-width": "328",
      "src": "/images/sub/introduction/introduction-main-mobile.png"
    }
  }), _c('v-img', {
    staticClass: "d-none d-md-block mt-60px",
    attrs: {
      "src": "/images/sub/introduction/introduction-main.png"
    }
  })], 1), _c('page-section-primary', {
    staticClass: "page-section--sm primary lighten-4 secondary--text text--darken-4"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "7"
    }
  }, [_c('u-tit-wrap-default', [_c('u-tit-default', {
    staticClass: "font-secondary font-weight-regular text-center text-md-start"
  }, [_c('strong', [_vm._v("편안하지흉부외과")]), _vm._v("는 "), _c('br'), _vm._v(" 이렇게 "), _c('strong', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("진료")]), _vm._v("합니다. ")])], 1), _c('u-tit-default', {
    staticClass: "font-secondary font-weight-regular mb-16px mb-md-24 text-center text-md-start"
  }, [_c('div', {
    staticClass: "tit--xs mb-8px mb-md-16px"
  }, [_vm._v("환자에게 가장 안전하고 효과적인 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("치료를 제공하기 위해서는")]), _c('div', {
    staticClass: "tit--sm"
  }, [_vm._v("의학의 법과 원칙인 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('strong', [_vm._v("교과서")]), _vm._v("에 따라 치료해야 한다.")])]), _c('u-txt-default', {
    staticClass: "txt--lg line-height-17"
  }, [_vm._v(" 우리나라 최고의 혈관수술 병원인 서울아산병원에서 10년 넘게 정식으로 수련 받고 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 근무하면서 배우고 익힌 환자진료의 이념(理念)입니다. 현재 교과서(guidelines)"), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 에서는 "), _c('strong', {
    staticClass: "grey--text text--darken-3"
  }, [_vm._v("클라리베인, 베나실, 고주파와 같은 ‘정맥내 요법’ 을 하지정맥의 표준치료")]), _vm._v("로 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 제시하고 있습니다. ")]), _c('div', {
    staticClass: "mt-24px mt-md-40px"
  }, [_c('v-row', {
    staticClass: "row--xs justify-center justify-md-start"
  }, [_c('v-col', {
    staticClass: "d-md-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "tile": "",
      "elevation": "4"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/introduction/guideline-01-mobile.jpg"
    }
  })], 1), _c('u-txt-default', {
    staticClass: "txt--lg text-center mt-8px mt-md-16px"
  }, [_vm._v("영국 하지정맥류 진료지침")])], 1), _c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "tile": "",
      "elevation": "4"
    }
  }, [_c('v-img', {
    staticClass: "guideline-image-01",
    attrs: {
      "src": "/images/sub/introduction/guideline-01.jpg"
    }
  })], 1), _c('u-txt-default', {
    staticClass: "txt--lg text-center mt-8px mt-md-16px"
  }, [_vm._v("영국 하지정맥류 진료지침")])], 1), _c('v-col', {
    staticClass: "d-md-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "elevation": "4"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/introduction/guideline-02-mobile.jpg"
    }
  })], 1), _c('u-txt-default', {
    staticClass: "txt--lg text-center mt-8px mt-md-16px"
  }, [_vm._v("미국 하지정맥류 진료지침")])], 1), _c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "elevation": "4"
    }
  }, [_c('v-img', {
    staticClass: "guideline-image-02",
    attrs: {
      "src": "/images/sub/introduction/guideline-02.jpg"
    }
  })], 1), _c('u-txt-default', {
    staticClass: "txt--lg text-center mt-8px mt-md-16px"
  }, [_vm._v("미국 하지정맥류 진료지침")])], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "doctor-bg",
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  })], 1)], 1), _c('page-section-primary', [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-weight-regular mb-16px mb-24px"
  }, [_vm._v("끊임없는 노력, "), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("뛰어난 의료서비스")])]), _c('u-txt-default', {
    staticClass: "txt--lg grey--text text--darken-3"
  }, [_vm._v(" 편안하지흉부외과에서는 하지정맥류의 진단 및 치료의 원칙을 끊임없이 공부하여"), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 환자에게 최선의 의료서비스를 제공할 수 있도록 노력합니다. ")])], 1), _c('main-story-slide'), _c('v-sheet', {
    attrs: {
      "rounded": "",
      "color": "grey lighten-5 mt-40px mt-md-60px pa-16px pa-md-40px"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-img', {
    staticClass: "border-x border-y",
    attrs: {
      "src": "/images/sub/introduction/Certified-01.png"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--sm text-center mt-6px mt-md-8px"
  }, [_vm._v("이승철 원장은 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('strong', [_vm._v("베나실(VenaSeal)의 "), _c('br'), _vm._v("안정적인 시술자")]), _vm._v("입니다.")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-img', {
    staticClass: "border-x border-y",
    attrs: {
      "src": "/images/sub/introduction/Certified-02.png"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--sm text-center mt-6px mt-md-8px"
  }, [_vm._v("RFA(고주파) 수술의 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('strong', [_vm._v("교육자문위원")]), _vm._v("으로 "), _c('br'), _vm._v(" 위촉된 이승철 원장")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/introduction/Certified-03.png"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--sm text-center mt-6px mt-md-8px"
  }, [_vm._v("편안하지흉부외과 "), _c('br'), _c('strong', [_vm._v("베나실 교육기관 선정")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/introduction/Certified-04.png"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--sm text-center mt-6px mt-md-8px"
  }, [_vm._v("편안하지흉부외과 "), _c('br'), _c('strong', [_vm._v("고주파 교육기관 선정")])])], 1)], 1)], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--sm page-section--last grey lighten-5 grey--text text--darken-4"
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-weight-regular mb-16px mb-24px"
  }, [_vm._v("편안하지흉부외과는 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("환자를 최우선으로")]), _vm._v(" 생각합니다.")]), _c('u-txt-default', {
    staticClass: "txt--lg grey--text text--darken-3"
  }, [_vm._v(" 검증된 하지정맥류 치료를 통해 환자들이 안심하고, "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("치료 후 건강하게 웃을 수 있는 병원을 꿈꿉니다. ")])], 1), _c('div', {
    staticClass: "marks-wrap py-24px py-md-40px"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "160",
      "src": "/images/sub/introduction/mark-01.png"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center text-lg-start",
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm"
  }, [_vm._v("전문성")]), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 흉부심장혈관외과 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 전문의 진료 ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "160",
      "src": "/images/sub/introduction/mark-02.png"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center text-lg-start",
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm"
  }, [_vm._v("실력")]), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 압도적인 수술경험, "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 하지정맥류 관련 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 모든 수술 가능 ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "160",
      "src": "/images/sub/introduction/mark-03.png"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center text-lg-start",
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm"
  }, [_vm._v("결과")]), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 환자 만족을 위한 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 철저한 사후관리 ")])], 1)], 1)], 1)], 1)], 1), _c('ul', {
    staticClass: "introduction-list"
  }, [_c('li', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary primary--text text--darken-4"
  }, [_vm._v("01")])], 1), _c('v-col', {
    staticClass: "pr-20px pr-md-0"
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-16px"
  }, [_vm._v("환자에게 가장 편리하고 효율적인 치료를 제공하기 위한 원스톱 시스템")]), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_c('strong', [_vm._v("내원 당일에 혈관 초음파를 이용한 진단")]), _vm._v("부터 환자분이 원하시는 경우 "), _c('strong', [_vm._v("증상에 맞는 치료 및 수술")]), _vm._v("까지 이루어지는 "), _c('strong', [_vm._v("원스톱 시스템")]), _vm._v("을 갖추고 있습니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 하지정맥류 수술 후에도 실밥제거, 소독 등의 "), _c('strong', [_vm._v("번거로운 외래 진료가 필요하지 않은 수술법을 적용")]), _vm._v("하여 환자분들의 불편을 최소화하고 있습니다. ")])], 1)], 1)], 1), _c('li', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary primary--text text--darken-4"
  }, [_vm._v("02")])], 1), _c('v-col', {
    staticClass: "pr-20px pr-md-0"
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-16px"
  }, [_vm._v("교차감염을 방지하여 환자의 안전을 도모하는 1인 1기구 사용 및 1회용 사용 원칙")]), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_c('ul', {
    staticClass: "dot-list dot-list--sm"
  }, [_c('li', [_vm._v("하지정맥 수술에 사용되는 모든 장비 및 소모품 1인 1기구 사용 및 1회용 사용")]), _c('li', [_vm._v("수술 장비에 사용되는 파이버(Fiber) 1회 사용 후 교체")]), _c('li', [_vm._v("수술 기구는 가장 최신의 소독 장비인 플라즈마 소독기로 완벽한 멸균 소독")]), _c('li', [_vm._v("편안하지흉부외과는 표준수술장비를 납품하는 토탈베인시스템과 함께 합니다.")])])])], 1)], 1)], 1), _c('li', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary primary--text text--darken-4"
  }, [_vm._v("03")])], 1), _c('v-col', {
    staticClass: "pr-20px pr-md-0"
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-16px"
  }, [_vm._v("수술 후에도 오롯이 나 혼자 편안하게 회복할 수 있는 1인 집중 회복실")]), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 하지정맥류 수술 후에는 "), _c('strong', [_vm._v("1인 집중 회복실에서 전문 간호사의 숙련된 의료 서비스")]), _vm._v("를 받을 수 있습니다. ")])], 1)], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }