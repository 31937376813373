<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류 비수술" tabActive="압박스타킹" bg="/images/sub/non-surgical/visual.jpg">
                <template #title>하지정맥류 비수술</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="tit--lg font-weight-regular mb-8px mb-md-16px">압박스타킹</div>
                        <div class="tit--xs primary--text text--darken-4">Compression Stockings</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg"> 발목을 강하게 압박하고, 위로 올라갈 수록 압력이 낮아지게 설계되어(pressure gradient) 아래에 있던 피들이 위로 올라가는데 도움을 주는 방법 </u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first">
            <v-sheet outlined rounded class="px-16px px-md-28px py-16px py-md-68px">
                <v-row class="row--xs">
                    <v-col cols="12" sm="6">
                        <v-img src="/images/sub/non-surgical/stocking-01.jpg" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-img src="/images/sub/non-surgical/stocking-02.jpg" />
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet rounded class="mt-40px mt-md-60px py-40px pa-md-40px grey lighten-5 mobile-full-width">
                <v-row class="row--xs">
                    <v-col cols="12" md="6">
                        <u-tit-default class="tit--xs primary--text text--darken-4 mb-10px">압박스타킹의 가장 좋은 적응증</u-tit-default>
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>
                                    젊은 여성 등에서 초음파에서 이상이 없으면서 부종 <br />
                                    이외의 증상은 없는 경우
                                </li>
                                <li>임산부</li>
                                <li>수술 후 회복기간</li>
                                <li>노인(혈관 뿐 아니라 근육지지도 해줌)</li>
                            </ul>
                        </u-txt-default>
                    </v-col>

                    <v-col cols="12" md="6">
                        <u-tit-default class="tit--xs primary--text text--darken-4 mb-10px">압박스타킹의 금기</u-tit-default>
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>심한 피부 부작용(접촉성 피부염)이 있는 경우</li>
                                <li>착용 시 발과 발목의 통증이 심한 경우</li>
                                <li>심장병 또는 동맥질환이 있는 경우</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </v-sheet>

            <div class="pt-40px pt-md-24px">
                <v-row>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">장점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>수술 하지 않고도 증상을 어느정도 조절할 수 있음</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">제한점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>압박스타킹을 신고 있는 동안에만 증상의 개선이 있음</li>
                                <li>
                                    치아교정 처럼 어느 기간 이상 신는다고 해서 근본적인 치료가 되지 않음 <br class="d-none d-xl-block" />
                                    (즉, 신지 않으면 역류 및 증상이 바로 시작됨)
                                </li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </div>

            <v-sheet rounded class="primary lighten-5 mt-40px mt-md-80px pa-24px px-lg-80px py-lg-60px">
                <v-row class="row--xs">
                    <v-col cols="12" sm="6">
                        <v-img src="/images/sub/non-surgical/stocking-action.png" />
                        <u-txt-default class="txt--lg txt--dark text-center mt-8px mt-md-16px">압박스타킹의 작용</u-txt-default>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-img src="/images/sub/non-surgical/stocking-taboo.png" />
                        <u-txt-default class="txt--lg txt--dark text-center mt-8px mt-md-16px">압박스타킹의 금기인 경우</u-txt-default>
                    </v-col>
                </v-row>
            </v-sheet>
        </page-section-primary>

        <section class="sigvaris">
            <v-img src="/images/sub/non-surgical/sigvaris-mobile.jpg" class="mx-auto d-md-none" />
            <v-img max-width="1200" src="/images/sub/non-surgical/sigvaris.jpg" class="mx-auto d-none d-md-block" />
        </section>

        <page-section-primary class="page-section--xs page-section--last grey lighten-5">
            <v-row class="row--sm">
                <template v-for="item in items">
                    <v-col :key="item" cols="6" md="3">
                        <v-card :href="item.link" target="_blank" color="transparent" class="shorts-card" flat tile>
                            <v-img :src="item.src" class="rounded">
                                <div class="v-image__overlay">
                                    <div class="play-button">
                                        <v-icon large class="white--text">mdi-play</v-icon>
                                    </div>
                                </div>
                            </v-img>
                            <u-tit-default class="tit--xxs font-secondary font-weight-medium text-center mt-8px mt-md-16px white-pre-wrap">
                                {{ item.title }}
                            </u-tit-default>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {
            items: [
                {
                    link: "https://youtube.com/shorts/cFnMu8F30r0",
                    src: "/images/sub/non-surgical/stocking-shorts-01.jpg",
                    title: "하지정맥류 - \r\n운동 할 때 압박스타킹 \r\n신는게 좋나요?",
                },
                {
                    link: "https://www.youtube.com/shorts/UzpRwT_lQNQ",
                    src: "/images/sub/non-surgical/stocking-shorts-02.jpg",
                    title: "하지정맥류 비수술치료 - \r\n압박스타킹은 얼마나 신어야 할까요?",
                },
                {
                    link: "https://www.youtube.com/shorts/-YlkWJRnETg",
                    src: "/images/sub/non-surgical/stocking-shorts-03.jpg",
                    title: "하지정맥류 비수술치료 - \r\n하지정맥류 압박스타킹은 어디서 구매?",
                },
                {
                    link: "https://www.youtube.com/shorts/0e_dJjd30u8",
                    src: "/images/sub/non-surgical/stocking-shorts-04.jpg",
                    title: "하지정맥류 비수술치료 - \r\n압박스타킹은 언제신나요?",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.sigvaris {
    background-color: #fcf2e9;
}
.shorts-card {
    .v-image {
        position: relative;
        &__overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            display: flex;
            justify-content: center;
            align-items: center;

            .play-button {
                width: 60px;
                height: 60px;
                border: 6px solid white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.mobile-full-width {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100%;
        background-color: inherit;
        z-index: -1;
    }
}
@media (min-width: 768px) {
    .mobile-full-width::before {
        display: none;
    }
}
</style>
