var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류 수술",
            "tabActive": "베나실",
            "bg": "/images/sub/surgery/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류 수술")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--lg font-weight-regular mb-8px mb-md-16px"
  }, [_vm._v("베나실")]), _c('div', {
    staticClass: "tit--xs primary--text text--darken-4"
  }, [_vm._v("VenaSeal™ Closure System (Cyanoacrylate)")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 베나실은 최근에 도입된 치료법으로 역류가 생긴 혈관에 Cyanoacrylate(의료용 생체접착제)로 혈관을 접착 폐쇄하여 역류를 차단하는 방법입니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 기존 방법과 달리 물리적인 손상이나 열로 인한 손상이 없어 어떤 방법보다 수술 후 통증이 확연히 적습니다. ")])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first"
  }, [_c('v-sheet', {
    staticClass: "pa-20px",
    attrs: {
      "rounded": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "1008",
      "src": "/images/sub/surgery/venaseal.jpg"
    }
  })], 1), _c('div', {
    staticClass: "pt-40px pt-md-60px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("특징 및 장점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("정맥내요법 중 열을 사용하지 않는 비열치료법 중 하나")]), _c('li', [_vm._v("열에 의한 신경손상이 없음")]), _c('li', [_vm._v("시술 중 통증이 적어 전신마취나 척추마취 없이 수면마취로 수술이 가능")]), _c('li', [_vm._v("추가적인 정맥절제술이 필요 없어 흉터가 거의 없음")]), _c('li', [_vm._v("퇴원 후 즉시 일상 생활 가능")]), _c('li', [_vm._v(" 미국, 유럽 및 대한민국 식약처(FDA, CE, KFDA)의 안전성 시험을 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 정식으로 거친 후 허가된 방법 ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("단점 및 부작용")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("상대적으로 고가인 수술비")]), _c('li', [_vm._v("이물반응에 의한 일시적 알러지반응 "), _c('br', {
    staticClass: "d-sm-none"
  }), _vm._v("(가려움, 부종 등)")]), _c('li', [_vm._v("심부정맥혈전증 (매우 희박)")])])])], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "primary lighten-5 mt-60px mt-md-80px pa-24px px-lg-80px py-lg-60px",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/surgery/venaseal-image-01.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/surgery/venaseal-image-02.jpg"
    }
  })], 1)], 1)], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs grey lighten-5 page-section--last"
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit mb-8px mb-md-16px"
  }, [_vm._v("베나실 실력이 검증된 이승철 원장님")]), _c('div', {
    staticClass: "tit--sm font-weight-regular"
  }, [_vm._v("베나실 수술경험이 매우 풍부하여 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("많은 노하우를 가지고 있습니다.")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg txt--dark text-center"
  }, [_c('div', {
    staticClass: "mb-8px mb-md-16px"
  }, [_vm._v(" 베나실은 정맥 내 요법 중 가장 최신의 방법이지만, 복잡하고 정확한 기술을 필요로 하므로 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 가급적 베나실 경험이 풍부한 전문의에게 수술 받는 것이 좋습니다. ")]), _c('div', [_vm._v("미국 베나실 권위자 Dr.Jones가 이승철 원장님의 베나실 Live Surgery 참관하는 등, "), _c('strong', [_vm._v("베나실의 세계적인 대가들과 함께")]), _vm._v("하였습니다.")])]), _c('v-sheet', {
    staticClass: "mt-40px mt-md-60px pa-16px pa-md-40px px-xl-80px",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "276",
      "src": "/images/main/professionalism-1-2.png"
    }
  })], 1), _c('v-col', [_c('u-tit-default', {
    staticClass: "tit--sm text-center text-lg-start font-weight-regular line-height-17"
  }, [_vm._v(" 편안하지 흉부외과는 "), _c('strong', [_vm._v("2020년 10월")]), _vm._v(" "), _c('br', {
    staticClass: "d-lg-none d-xl-block"
  }), _vm._v(" VenaSeal 수술 "), _c('strong', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("1,000례(환자기준)")]), _vm._v("를 "), _c('br', {
    staticClass: "d-lg-none d-xl-block"
  }), _vm._v(" (주)메드트로닉코리아로부터 공식 "), _c('br', {
    staticClass: "d-lg-none d-xl-block"
  }), _vm._v(" 확인받았습니다. ")])], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "mt-60px mt-md-80px",
    attrs: {
      "color": "transparent",
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/surgery/venaseal-interact-01.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/surgery/venaseal-interact-02.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('video', {
    staticClass: "w-100 rounded",
    attrs: {
      "controls": "",
      "src": "/videos/venaseal.mp4"
    }
  })])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }