var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "편안하지흉부외과",
            "tabActive": "의료진 소개",
            "bg": "/images/sub/introduction/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("편안하지흉부외과")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--xs primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("Medical team")]), _c('div', {
    staticClass: "tit--lg font-weight-regular"
  }, [_vm._v("의료진 소개")])])], 1), _c('page-section-primary', {
    staticClass: "page-section--sm secondary darken-4 section-bg"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "lg": "7"
    }
  }, [_c('div', {
    staticClass: "py-md-48px"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg"
  }, [_c('v-img', {
    attrs: {
      "max-width": "542",
      "src": "/images/sub/introduction/team/team-slogan.png"
    }
  })], 1), _c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary font-weight-regular"
  }, [_c('div', {
    staticClass: "tit--xs mb-8px mb-md-16px primary--text"
  }, [_vm._v("내과 및 흉부심혈관외과 전문의")]), _c('div', [_c('strong', [_vm._v("이승철")]), _vm._v(" 대표원장")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg txt--light"
  }, [_vm._v(" 우리나라 최고의 혈관수술 병원인 서울아산병원에서 10년 넘게 정식으로 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 수련 받고 임상강사로 근무하면서 하지정맥류 치료 및 수술의 노하우를 쌓았습니다. ")]), _c('v-img', {
    staticClass: "w-50 w-md-40 mt-8px mt-md-16px",
    attrs: {
      "src": "/images/sub/introduction/team/asan-logo.svg",
      "max-width": "218"
    }
  })], 1)]), _c('v-col', {
    staticClass: "doctor-bg",
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "5"
    }
  })], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--sm"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("프로필")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("가톨릭의과대학교(학사, 학과장상 졸업)")]), _c('li', [_vm._v("울산대학교(서울아산병원) 대학원(석사)")]), _c('li', [_vm._v("서울대학교 대학원(박사)")]), _c('li', [_vm._v("서울아산병원 흉부심장혈관외과 전공의")]), _c('li', [_vm._v("서울아산병원 흉부심장혈관외과 임상강사")]), _c('li', [_vm._v("대한흉부심장혈관외과학회 정회원(전문의)")]), _c('li', [_vm._v("대한정맥학회 정회원 및 학술위원")]), _c('li', [_vm._v("대한 결핵및호흡기학회 정회원(전문의)-내과/외과 계열 동시 전문의")]), _c('li', [_vm._v("미국정맥학회(American College of Phlebology) 회원")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("학회 및 연수활동")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("대한흉부심장혈관외과학회 정회원(전문의)")]), _c('li', [_vm._v("대한정맥학회 정회원 및 학술위원(전문의)")]), _c('li', [_vm._v("대한 결핵및호흡기학회 정회원(전문의)")]), _c('li', [_vm._v("미국정맥학회 (American College of Phlebology) 회원")]), _c('li', [_vm._v("WHO LTBI guideline development consultation meeting, Geneva, board member. 2014")]), _c('li', [_vm._v("The STS(The Society of Thoracic Surgeons, 미국흉부외과학회) -47th Annual Metting in San Diego, California, 2011 연자발표")]), _c('li', [_vm._v("The 3rd Congress of Asia-Pacific Pediatric Cardiac Society (아시아흉부외과학회), Osaka Japan.2011 연자발표")]), _c('li', [_vm._v("World Congress of Internal Medicine, Seoul, Korea, 2014 연자발표")]), _c('li', [_vm._v("The 3rd Meeting of Asian TB Experts Community, Macau, 2014 연자 발표")]), _c('li', [_vm._v("World TB Advisory Board Meeting, Seoul, Korea 2015 연자 발표")]), _c('li', [_vm._v("대한정맥학회 2017 춘계학회 연자발표 :Scierotherapy - Procedure and Posttreatment Management")]), _c('li', [_vm._v("대한흉부심장혈관외과의사회 2018 춘계학회 연자 발표 : Varicose veins treatment with Venaseal")]), _c('li', [_vm._v("대한흉부심장혈관외과의사회 2018 추계학회 연자 발표 : Comparison between Non thermal & Thermal ablation treatment in varicose vein")]), _c('li', [_vm._v(" APECS(Advances in Peripheral EndovasCular Strategies) "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 2018 연자 발표: Big match comes true: Venaseal vs Clarivein ")]), _c('li', [_vm._v(" APECS(Advances in Peripheral EndovasCular Strategies) "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 2019 연자 발표: Do we need to make even distribution of sclerosant during MOCA? ")]), _c('li', [_vm._v("대한정맥학회 2020 추계학회 연자 발표: The latest update on history of foam therapy")]), _c('li', [_vm._v("서울시의사회 의사대상 강의 2021: 하지정맥류의 진단과 치료")]), _c('li', [_vm._v("대한흉부심장혈관외과학회 하지정맥연구회 2022 추계학회 연자 발표: Reflux of distal SSV contributed by reflux of posterior arch vein")]), _c('li', [_vm._v("Merit Medical World Wide Webina : Top 7 tips for Clarivein 2022")]), _c('li', [_vm._v("대한정맥학회 2023 춘계학회 연자 발표: How to avoid & manage complications after Non-Thermal Ablation")]), _c('li', [_vm._v("대한정맥학회 – 하지정맥류 진단을 위한 근거 중심의 초음파 검사법 집필 위원 (2023)")]), _c('li', [_vm._v("대한의학회 – 하지정맥류 초음파검사 진료지침 개발위원(5인중 1인) (2023)")])])])], 1)], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--sm primary lighten-4 grey--text text--darken-4"
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-weight-regular mb-16px mb-24px"
  }, [_vm._v("학회활동")]), _c('u-txt-default', {
    staticClass: "txt--lg grey--text text--darken-3"
  }, [_vm._v(" 편안하지흉부외과 이승철 원장은 매년 학회발표를 통해, "), _c('br', {
    staticClass: "d-md-none d-lg-block"
  }), _vm._v(" 앞서가는 경험을 국내외 여러 의사들과 공유하고 있습니다. ")])], 1), _c('div', {
    staticClass: "main-slide main-slide--academy"
  }, [_c('swiper', {
    attrs: {
      "options": _vm.academyOptions
    }
  }, [_vm._l(_vm.academy, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('img', {
      staticClass: "w-100 rounded d-block",
      attrs: {
        "src": item.src,
        "alt": ""
      }
    })])];
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button-prev v-size--xx-large",
    attrs: {
      "color": "primary darken-4",
      "outlined": "",
      "icon": ""
    }
  }, [_c('v-icon', {
    staticClass: "primary--text"
  }, [_vm._v("chevron_left")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button-next v-size--xx-large",
    attrs: {
      "color": "primary darken-4",
      "outlined": "",
      "icon": ""
    }
  }, [_c('v-icon', {
    staticClass: "primary--text"
  }, [_vm._v("chevron_right")])], 1)], 1)], 1)], 1)], 2)], 1), _c('div', {
    staticClass: "thumbs-slide thumbs-slide--academy"
  }, [_c('swiper', {
    attrs: {
      "options": _vm.academyThumbs
    }
  }, [_vm._l(_vm.academy, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('img', {
      staticClass: "w-100 rounded-sm d-block",
      attrs: {
        "src": item.src,
        "alt": ""
      }
    })])];
  })], 2)], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--sm"
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-weight-regular mb-16px mb-24px"
  }, [_vm._v("교류 및 연수활동")]), _c('u-txt-default', {
    staticClass: "txt--lg grey--text text--darken-3"
  }, [_vm._v(" 편안하지흉부외과에는 매년 국내외 많은 의사들이 이승철 원장님의 경험을 배우기 위해 방문하고 있습니다. ")])], 1), _c('div', {
    staticClass: "main-slide main-slide--interact"
  }, [_c('swiper', {
    attrs: {
      "options": _vm.interactOptions
    }
  }, [_vm._l(_vm.interact, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('img', {
      staticClass: "w-100 rounded d-block",
      attrs: {
        "src": item.src,
        "alt": ""
      }
    })])];
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button-prev v-size--xx-large",
    attrs: {
      "color": "primary darken-4",
      "outlined": "",
      "icon": ""
    }
  }, [_c('v-icon', {
    staticClass: "primary--text"
  }, [_vm._v("chevron_left")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button-next v-size--xx-large",
    attrs: {
      "color": "primary darken-4",
      "outlined": "",
      "icon": ""
    }
  }, [_c('v-icon', {
    staticClass: "primary--text"
  }, [_vm._v("chevron_right")])], 1)], 1)], 1)], 1)], 2)], 1), _c('div', {
    staticClass: "thumbs-slide thumbs-slide--interact"
  }, [_c('swiper', {
    attrs: {
      "options": _vm.interactThumbs
    }
  }, [_vm._l(_vm.interact, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('img', {
      staticClass: "w-100 rounded-sm d-block",
      attrs: {
        "src": item.src,
        "alt": ""
      }
    })])];
  })], 2)], 1)], 1), _c('section', {
    staticClass: "heart-bg secondary"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "end",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex align-center py-60px py-lg-94px"
  }, [_c('div', {
    staticClass: "pl-xl-60px"
  }, [_c('u-tit-wrap-default', [_c('u-tit-default', {
    staticClass: "font-secondary font-weight-regular"
  }, [_vm._v(" 하지정맥류는 "), _c('br'), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("흉부심장혈관외과 전문의")]), _vm._v("가 "), _c('br'), _vm._v(" 잘한다! ")])], 1), _c('u-txt-default', {
    staticClass: "txt--lg txt--light line-height-17 mb-16px mb-md-24px"
  }, [_vm._v(" 하지정맥류는 정맥(심장으로 혈액을 되돌려 보내는 혈관) "), _c('br'), _vm._v(" 내부의 판막이 손상되어 심장으로 혈액이 못가고 역류하는 질환입니다. "), _c('br'), _vm._v(" 따라서 심장과 혈관을 모두 잘 이해하는 ‘흉부심장혈관외과 전문의’에게 진료를 받는 것이 중요합니다. ")]), _c('u-tit-default', {
    staticClass: "font-secondary font-weight-regular line-height-135 line-height-md-1"
  }, [_c('div', {
    staticClass: "tit--xs mb-8px mb-md-16px"
  }, [_vm._v("흉부외과의 정식 명칭은 ‘흉부심장혈관외과’로,")]), _c('div', {
    staticClass: "tit--sm"
  }, [_c('strong', [_vm._v("심장과 혈관을 전문적으로 진료")]), _vm._v("하는 곳입니다.")])])], 1)])])], 1)], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--sm"
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-weight-regular mb-16px mb-24px"
  }, [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("실력있는 흉부심장혈관외과")]), _vm._v("의 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v(" 정확한 진단")]), _c('u-txt-default', {
    staticClass: "txt--lg grey--text text--darken-3"
  }, [_vm._v("편안하지흉부외과 이승철 원장님은 혈관 초음파를 통해 하지정맥을 일으키는 원인 혈관 및 미세 혈관까지 정확히 진단합니다.")])], 1), _c('div', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "h-100 d-flex justify-center align-center overflow-hidden",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('v-img', {
    staticClass: "w-100 mx-auto label-img",
    attrs: {
      "src": "/images/sub/introduction/team/diagnosis-01.jpg",
      "max-width": "280"
    }
  }, [_c('u-txt-default', [_vm._v("초음파, H-60")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/introduction/team/diagnosis-02.png"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "pt-40px pt-md-60px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/introduction/team/diagnosis-03.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/sub/introduction/team/diagnosis-04.png"
    }
  })], 1)], 1)], 1), _c('u-txt-default', {
    staticClass: "txt--lg text-center mt-40px mt-md-60px"
  }, [_vm._v(" 편안하지에서 하지정맥류 진단 및 치료에 사용되는 혈관 초음파 장비는 영상에서도 바늘이 뚜렷하게 보이도록 도와주는 Needle Mate 기능이 있으며, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 하지정맥류 진단 및 치료를 안전하고 정확하게 할 수 있는 가장 적합한 초음파 장비입니다. ")])], 1), _c('page-section-primary', {
    staticClass: "page-section--sm page-section--last grey lighten-5"
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-weight-regular mb-16px mb-24px"
  }, [_c('strong', [_vm._v("하지정맥류")]), _vm._v(" 관련 현존하는 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('strong', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("6가지 근본수술 모두 시행")])]), _c('u-txt-default', {
    staticClass: "txt--lg grey--text text--darken-3"
  }, [_vm._v("환자의 상태를 정확히 진단하고, 그에 따른 가장 좋은 수술법을 선택합니다. ")])], 1), _c('v-row', {
    staticClass: "ma-n6px ma-md-n20px"
  }, [_vm._l(_vm.surgery, function (item) {
    return [_c('v-col', {
      key: item,
      staticClass: "pa-6px pa-md-20px",
      attrs: {
        "cols": "4",
        "lg": "2"
      }
    }, [_c('main-surgery-dialog', _vm._b({
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-card', _vm._g(_vm._b({
            staticClass: "surgery-card",
            attrs: {
              "flat": "",
              "tile": "",
              "color": "transparent"
            }
          }, 'v-card', attrs, false), on), [_c('div', {
            staticClass: "v-card__image-wrap rounded overflow-hidden"
          }, [_c('v-img', {
            staticClass: "v-card__image",
            attrs: {
              "src": item.thumb
            }
          }, [_c('div', {
            staticClass: "more-view d-flex justify-center align-center mb-2px mb-sm-4px mb-md-6px"
          }, [_c('u-txt-default', {
            staticClass: "txt--xs font-size-md-16 primary--text text--darken-4 line-height-1"
          }, [_vm._v("More View")]), _c('v-img', {
            staticClass: "w-10px w-md-100 ml-4px ml-md-8px",
            attrs: {
              "src": "/images/icon/icon-plus.svg",
              "max-width": "16"
            }
          })], 1)])], 1), _c('v-card-title', {
            staticClass: "justify-center font-secondary mt-8px mt-md-24px px-0 py-12px py-md-16px"
          }, [_c('div', {
            staticClass: "text-center"
          }, [_c('u-tit-default', {
            staticClass: "tit--xs font-weight-medium line-height-1"
          }, [_vm._v(_vm._s(item.name_ko))])], 1)])], 1)];
        }
      }], null, true)
    }, 'main-surgery-dialog', item, false))], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }