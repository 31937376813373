<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류 수술" tabActive="발거술" bg="/images/sub/surgery/visual.jpg">
                <template #title>하지정맥류 수술</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="tit--lg font-weight-regular mb-8px mb-md-16px">발거술</div>
                        <div class="tit--xs primary--text text--darken-4">High Iigation and Stripping</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg"
                    >문제가 되는 혈관을 수술적 방법을 통해 제거하여 없애는 방법입니다. <br class="d-none d-xl-block" />
                    혈관이 심하게 울퉁불퉁하거나 혈전이 있어 혈관 전용 레이저 수술이 부적합한 경우에 시행됩니다.</u-txt-default
                >
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first">
            <v-sheet rounded outlined class="pa-20px py-md-68px">
                <v-row class="row--xs">
                    <v-col cols="12" md="6">
                        <v-img max-width="508" src="/images/sub/surgery/stripping-01.jpg" class="ml-auto" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-img max-width="508" src="/images/sub/surgery/stripping-02.jpg" class="mr-auto" />
                    </v-col>
                </v-row>
            </v-sheet>

            <div class="pt-40px pt-md-60px">
                <v-row>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">특징 및 장점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>
                                    하지정맥류가 많이 진행되어 혈관이 매우 구불구불한 경우 등 <br class="d-none d-lg-block" />
                                    '정맥내요법'이 불가능한 경우 적용 가능
                                </li>
                                <li>우리나라의 경우 '국민건강보험' 적용 가능</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">단점 및 부작용</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>
                                    마취, 통증, 흉터, 일상 생활 복귀, 재발 등 모든 면에서 <br class="d-none d-lg-block" />
                                    '정맥내치료요법'에 비해 불리
                                </li>
                                <li>미국 유럽 등 선진국가에서 이미 우선 치료 방법에서 제외</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
