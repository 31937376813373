var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류 비수술",
            "tabActive": "압박스타킹",
            "bg": "/images/sub/non-surgical/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류 비수술")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--lg font-weight-regular mb-8px mb-md-16px"
  }, [_vm._v("압박스타킹")]), _c('div', {
    staticClass: "tit--xs primary--text text--darken-4"
  }, [_vm._v("Compression Stockings")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v(" 발목을 강하게 압박하고, 위로 올라갈 수록 압력이 낮아지게 설계되어(pressure gradient) 아래에 있던 피들이 위로 올라가는데 도움을 주는 방법 ")])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first"
  }, [_c('v-sheet', {
    staticClass: "px-16px px-md-28px py-16px py-md-68px",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/non-surgical/stocking-01.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/non-surgical/stocking-02.jpg"
    }
  })], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "mt-40px mt-md-60px py-40px pa-md-40px grey lighten-5 mobile-full-width",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--xs primary--text text--darken-4 mb-10px"
  }, [_vm._v("압박스타킹의 가장 좋은 적응증")]), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v(" 젊은 여성 등에서 초음파에서 이상이 없으면서 부종 "), _c('br'), _vm._v(" 이외의 증상은 없는 경우 ")]), _c('li', [_vm._v("임산부")]), _c('li', [_vm._v("수술 후 회복기간")]), _c('li', [_vm._v("노인(혈관 뿐 아니라 근육지지도 해줌)")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--xs primary--text text--darken-4 mb-10px"
  }, [_vm._v("압박스타킹의 금기")]), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("심한 피부 부작용(접촉성 피부염)이 있는 경우")]), _c('li', [_vm._v("착용 시 발과 발목의 통증이 심한 경우")]), _c('li', [_vm._v("심장병 또는 동맥질환이 있는 경우")])])])], 1)], 1)], 1), _c('div', {
    staticClass: "pt-40px pt-md-24px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("장점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("수술 하지 않고도 증상을 어느정도 조절할 수 있음")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("제한점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("압박스타킹을 신고 있는 동안에만 증상의 개선이 있음")]), _c('li', [_vm._v(" 치아교정 처럼 어느 기간 이상 신는다고 해서 근본적인 치료가 되지 않음 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" (즉, 신지 않으면 역류 및 증상이 바로 시작됨) ")])])])], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "primary lighten-5 mt-40px mt-md-80px pa-24px px-lg-80px py-lg-60px",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/non-surgical/stocking-action.png"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg txt--dark text-center mt-8px mt-md-16px"
  }, [_vm._v("압박스타킹의 작용")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/non-surgical/stocking-taboo.png"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg txt--dark text-center mt-8px mt-md-16px"
  }, [_vm._v("압박스타킹의 금기인 경우")])], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "sigvaris"
  }, [_c('v-img', {
    staticClass: "mx-auto d-md-none",
    attrs: {
      "src": "/images/sub/non-surgical/sigvaris-mobile.jpg"
    }
  }), _c('v-img', {
    staticClass: "mx-auto d-none d-md-block",
    attrs: {
      "max-width": "1200",
      "src": "/images/sub/non-surgical/sigvaris.jpg"
    }
  })], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--last grey lighten-5"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('v-card', {
      staticClass: "shorts-card",
      attrs: {
        "href": item.link,
        "target": "_blank",
        "color": "transparent",
        "flat": "",
        "tile": ""
      }
    }, [_c('v-img', {
      staticClass: "rounded",
      attrs: {
        "src": item.src
      }
    }, [_c('div', {
      staticClass: "v-image__overlay"
    }, [_c('div', {
      staticClass: "play-button"
    }, [_c('v-icon', {
      staticClass: "white--text",
      attrs: {
        "large": ""
      }
    }, [_vm._v("mdi-play")])], 1)])]), _c('u-tit-default', {
      staticClass: "tit--xxs font-secondary font-weight-medium text-center mt-8px mt-md-16px white-pre-wrap"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }