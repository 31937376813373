var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류 비수술",
            "tabActive": "경구약(정맥순환개선제)",
            "bg": "/images/sub/non-surgical/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류 비수술")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--lg font-weight-regular mb-8px mb-md-16px"
  }, [_vm._v("경구약(정맥순환개선제)")]), _c('div', {
    staticClass: "tit--xs primary--text text--darken-4"
  }, [_vm._v("Oral Venoactive Drugs")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v("약 복용을 통해 정맥벽과 판막의 탄력을 개선시켜 정맥혈의 저류를 방지하고, 염증작용을 억제하며, 모세혈관의 저항을 높이고, 흡수율을 증가시키는 방법")])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first page-section--last"
  }, [_c('v-sheet', {
    staticClass: "px-16px px-md-100px py-16px py-md-46px",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/non-surgical/drugs.png"
    }
  })], 1), _c('v-sheet', {
    staticClass: "mt-40px mt-md-60px pa-16px pa-md-40px grey lighten-5",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--xs primary--text text--darken-4 mb-10px"
  }, [_vm._v("경구약의 가장 좋은 적응증 ")]), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v(" 젊은 여성 등에서 초음파에서 이상이 없으면서 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 부종 이외의 증상은 없는 경우 ")]), _c('li', [_vm._v(" 수술 후 회복기간 또는 수술 후 다른 증상은 호전됐으나 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 부종은 남아있는 경우 ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--xs primary--text text--darken-4 mb-10px"
  }, [_vm._v("경구약의 금기")]), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("경구약에 대한 알러지 반응이 있는 경우")]), _c('li', [_vm._v("복용시 심한 피부발적이나 위장장애가 있는 경우")])])])], 1)], 1)], 1), _c('div', {
    staticClass: "pt-16px pt-md-24px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("장점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("수술 하지 않고도 증상을 어느정도 조절할 수 있음")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("제한점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("근본적인 치료법이 아님")]), _c('li', [_vm._v("전신에 영향을 미치는 먹는 약을 오래 복용 하기 부담스러움(부작용 등)")]), _c('li', [_vm._v("효과가 아주 오래 지속되지 않는 경우가 많음")])])])], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "primary lighten-5 mt-60px mt-md-80px pa-24px px-lg-80px py-lg-60px",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/non-surgical/drug-01.png"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg mt-8px mt-md-16px"
  }, [_vm._v(" The treatment or combination of treatments for "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" symptomatic chronic vein disease shall be selected on "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" an individual basis, determined by different factors ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/non-surgical/drug-02.png"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg mt-8px mt-md-16px"
  }, [_c('div', [_vm._v("6.1.2.1Indication.")]), _c('div', {
    staticClass: "mt-4px mt-md-8px"
  }, [_vm._v(" If it is impossible or considered undesirable to treat "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" asymptomatic varicose vein, or if swelling and feelings "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" of heaviness persist even after invasive treatment, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" the prescription of oral vein treatment drugs of proven "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" effectiveness can be considered ([96,103]; Fig.1). ")])])], 1)], 1)], 1), _c('div', {
    staticClass: "mt-60px mt-md-80px"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/non-surgical/drug-table.jpg"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg txt--dark text-center mt-10px"
  }, [_vm._v("정맥순환개선제의 약리학적 기전")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }