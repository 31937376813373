<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류 비수술" tabActive="경구약(정맥순환개선제)" bg="/images/sub/non-surgical/visual.jpg">
                <template #title>하지정맥류 비수술</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="tit--lg font-weight-regular mb-8px mb-md-16px">경구약(정맥순환개선제)</div>
                        <div class="tit--xs primary--text text--darken-4">Oral Venoactive Drugs</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg">약 복용을 통해 정맥벽과 판막의 탄력을 개선시켜 정맥혈의 저류를 방지하고, 염증작용을 억제하며, 모세혈관의 저항을 높이고, 흡수율을 증가시키는 방법</u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first page-section--last">
            <v-sheet outlined rounded class="px-16px px-md-100px py-16px py-md-46px">
                <v-img src="/images/sub/non-surgical/drugs.png" />
            </v-sheet>

            <v-sheet rounded class="mt-40px mt-md-60px pa-16px pa-md-40px grey lighten-5">
                <v-row class="row--xs">
                    <v-col cols="12" md="6">
                        <u-tit-default class="tit--xs primary--text text--darken-4 mb-10px">경구약의 가장 좋은 적응증 </u-tit-default>
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>
                                    젊은 여성 등에서 초음파에서 이상이 없으면서 <br class="d-none d-lg-block" />
                                    부종 이외의 증상은 없는 경우
                                </li>
                                <li>
                                    수술 후 회복기간 또는 수술 후 다른 증상은 호전됐으나 <br class="d-none d-lg-block" />
                                    부종은 남아있는 경우
                                </li>
                            </ul>
                        </u-txt-default>
                    </v-col>

                    <v-col cols="12" md="6">
                        <u-tit-default class="tit--xs primary--text text--darken-4 mb-10px">경구약의 금기</u-tit-default>
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>경구약에 대한 알러지 반응이 있는 경우</li>
                                <li>복용시 심한 피부발적이나 위장장애가 있는 경우</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </v-sheet>

            <div class="pt-16px pt-md-24px">
                <v-row>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">장점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>수술 하지 않고도 증상을 어느정도 조절할 수 있음</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">제한점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>근본적인 치료법이 아님</li>
                                <li>전신에 영향을 미치는 먹는 약을 오래 복용 하기 부담스러움(부작용 등)</li>
                                <li>효과가 아주 오래 지속되지 않는 경우가 많음</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </div>

            <v-sheet rounded class="primary lighten-5 mt-60px mt-md-80px pa-24px px-lg-80px py-lg-60px">
                <v-row class="row--xs">
                    <v-col cols="12" sm="6">
                        <v-img src="/images/sub/non-surgical/drug-01.png" />
                        <u-txt-default class="txt--lg mt-8px mt-md-16px">
                            The treatment or combination of treatments for <br class="d-none d-xl-block" />
                            symptomatic chronic vein disease shall be selected on <br class="d-none d-xl-block" />
                            an individual basis, determined by different factors
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-img src="/images/sub/non-surgical/drug-02.png" />
                        <u-txt-default class="txt--lg mt-8px mt-md-16px">
                            <div>6.1.2.1Indication.</div>
                            <div class="mt-4px mt-md-8px">
                                If it is impossible or considered undesirable to treat <br class="d-none d-xl-block" />
                                asymptomatic varicose vein, or if swelling and feelings <br class="d-none d-xl-block" />
                                of heaviness persist even after invasive treatment, <br class="d-none d-xl-block" />
                                the prescription of oral vein treatment drugs of proven <br class="d-none d-xl-block" />
                                effectiveness can be considered ([96,103]; Fig.1).
                            </div>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </v-sheet>

            <div class="mt-60px mt-md-80px">
                <v-img src="/images/sub/non-surgical/drug-table.jpg" />
                <u-txt-default class="txt--lg txt--dark text-center mt-10px">정맥순환개선제의 약리학적 기전</u-txt-default>
            </div>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {
            items: [
                {
                    link: "https://youtube.com/shorts/cFnMu8F30r0",
                    src: "/images/sub/non-surgical/stocking-shorts-01.jpg",
                    title: "하지정맥류 - \r\n운동 할 때 압박스타킹 신는게 좋나요?",
                },
                {
                    link: "https://www.youtube.com/shorts/UzpRwT_lQNQ",
                    src: "/images/sub/non-surgical/stocking-shorts-02.jpg",
                    title: "하지정맥류 비수술치료 - \r\n압박스타킹은 얼마나 신어야 할까요?",
                },
                {
                    link: "https://www.youtube.com/shorts/-YlkWJRnETg",
                    src: "/images/sub/non-surgical/stocking-shorts-03.jpg",
                    title: "하지정맥류 비수술치료 - \r\n하지정맥류 압박스타킹은 어디서 구매?",
                },
                {
                    link: "https://www.youtube.com/shorts/0e_dJjd30u8",
                    src: "/images/sub/non-surgical/stocking-shorts-04.jpg",
                    title: "하지정맥류 비수술치료 - \r\n압박스타킹은 언제신나요?",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.sigvaris {
    background-color: #fcf2e9;
}
</style>
