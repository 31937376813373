var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "하지정맥류 비수술",
            "tabActive": "혈관경화요법(주사치료)",
            "bg": "/images/sub/non-surgical/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("하지정맥류 비수술")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--lg font-weight-regular mb-8px mb-md-16px"
  }, [_vm._v("혈관경화요법(주사치료)")]), _c('div', {
    staticClass: "tit--xs primary--text text--darken-4"
  }, [_vm._v("Sclerotherapy")])])], 1), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_vm._v("경화제를 대상혈관에 직접 주입하여, 혈관벽에 화학적 손상을 줘서 혈관을 제거 하는 방법")])], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--xs page-section--first page-section--last"
  }, [_c('v-sheet', {
    staticClass: "px-16px px-md-28px py-16px py-md-82px",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/non-surgical/sclerotherapy-01.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/non-surgical/sclerotherapy-02.jpg"
    }
  })], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "mt-40px mt-md-60px pa-16px pa-md-40px grey lighten-5",
    attrs: {
      "rounded": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--xs primary--text text--darken-4 mb-10px"
  }, [_vm._v("혈관경화요법(주사치료)의 가장 좋은 적응증")]), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v(" 환자의 상태(노인 등), 혈관의 위치, 모양(관통정맥 등)에 따라 근본 치료가 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 불가한 경우 대안으로 사용 ")]), _c('li', [_vm._v("망상정맥 또는 거미양정맥 등에서 미용목적으로 치료")]), _c('li', [_vm._v("근본치료 후 가지정맥 등에서 남은 역류를 정리하는데 사용")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--xs primary--text text--darken-4 mb-10px"
  }, [_vm._v("혈관경화요법(주사치료)의 금기")]), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("주사약이 전신으로 확산되므로 알러지 등이 있으면 사용 불가")])])])], 1)], 1)], 1), _c('div', {
    staticClass: "pt-16px pt-md-24px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("특징 및 장점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("수술(근본치료)을 할 수 없는 경우 또는 위치에서 치료의 대안으로 사용가능")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular font-secondary mb-8px mb-md-16px"
  }, [_vm._v("제한점")]), _c('v-divider', {
    staticClass: "mb-24px mb-md-40px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "square-list"
  }, [_c('li', [_vm._v("근본적인 치료법이 아님")]), _c('li', [_vm._v(" 복재정맥 자체(main truncal vein)에는 효과가 매우 떨어져, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 다른 근본치료가 많이 나온 요즘엔 주치료 방법에서 제외 ")])])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }