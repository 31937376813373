<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류" tabActive="나도 하지정맥류?" bg="/images/sub/varicose/visual.jpg">
                <template #title>하지정맥류</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="tit--xs primary--text text--darken-4 mb-8px mb-md-16px">Self-diagnosis</div>
                        <div class="tit--lg font-weight-regular">하지정맥류 자가진단</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg">
                    하지정맥류는 장기간에 걸쳐 증상이 나타나므로 <br class="d-sm-none" />치료시기를 놓치기 쉽습니다. <br />
                    조금이라도 증상이 의심된다면, <br class="d-sm-none" />미리 자가 진단해보세요.
                </u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--last primary lighten-5">
            <v-sheet color="transparent">
                <v-row no-gutters>
                    <v-col cols="6" class="d-none d-xl-block">
                        <v-img height="100%" src="/images/sub/varicose/self-diagnosis.jpg" />
                    </v-col>
                    <v-col cols="12" xl="6" class="d-flex justify-center d-xl-block">
                        <!-- <v-img src="/images/sub/varicose/self-diagnosis-text.jpg" /> -->
                        <v-sheet max-width="600" class="pa-24px pa-md-60px">
                            <div>
                                <div class="mb-16px mb-md-24px">
                                    <v-row align="center" no-gutters>
                                        <v-col cols="auto">
                                            <v-img src="/images/icon/icon-checked-document.svg" class="diagnosis-icon" />
                                        </v-col>
                                        <v-col>
                                            <u-tit-default class="font-weight-regular font-secondary line-height-1">하지정맥류 관련 증상</u-tit-default>
                                        </v-col>
                                    </v-row>
                                </div>
                                <template v-for="item in items">
                                    <div :key="item">
                                        <v-row class="row--xxs align-md-center my-0">
                                            <v-col cols="auto">
                                                <v-img src="/images/icon/icon-checkbox-dark.svg" max-width="12px" class="d-lg-none mt-4px" />
                                                <v-img src="/images/icon/icon-checkbox-dark.svg" max-width="18px" class="d-none d-lg-block" />
                                            </v-col>
                                            <v-col>
                                                <p class="font-size-14 font-size-md-16">
                                                    {{ item.text }}
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </template>

                                <u-txt-default class="txt--lg primary--text pt-8px pt-md-16px line-height-135">
                                    해당 개수가 많을수록 정맥순환에 문제가 있을 수 있기 때문에 <br class="d-none d-sm-block"/>
                                    전문의에게 정확한 진단을 받는 것이 좋습니다.
                                </u-txt-default>
                            </div>

                            <div class="mt-24px mt-md-40px">
                                <div class="mb-16px mb-md-24px">
                                    <v-row align="center" no-gutters>
                                        <v-col cols="auto">
                                            <v-img src="/images/icon/icon-alarm.svg" class="diagnosis-icon" />
                                        </v-col>
                                        <v-col>
                                            <u-tit-default class="font-weight-regular font-secondary line-height-1">확진</u-tit-default>
                                        </v-col>
                                    </v-row>
                                </div>
                                <u-txt-default class="txt--lg mb-16px mb-md-24px">
                                    다리에 <strong>혈관이 울퉁불퉁</strong> 튀어 나와 있거나 <strong>파란 혈관</strong>이 <br class="d-none d-sm-block" />
                                    많이 두드러지게 보인다.
                                </u-txt-default>
                                <u-txt-default class="txt--lg reddish--text line-height-135">
                                    이런 경우 하지정맥류는 확실합니다. <br />
                                    그러나 치료의 필요성, 치료 범위, 치료 방법 등을 확인하기 <br class="d-none d-sm-block" />
                                    위해서는 병원에 방문하여 초음파 검사를 시행하여야 합니다.
                                </u-txt-default>
                            </div>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-sheet>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {
            items: [
                {
                    text: "저녁만 되면 다리가 많이 붓고 아프지만 아침이면 호전된다.",
                },
                {
                    text: "잘 때 쥐가 많이 나서 자주 깬다.",
                },
                {
                    text: "하루 종일 서서(또는 앉아서) 일하고 항상 다리가 피곤하다.",
                },
                {
                    text: "(여성의 경우) 생리 전에 항상 다리가 많이 아프다.",
                },
                {
                    text: "부모님 또는 형제자매 중 하지정맥류 환자가 있다.",
                },
                {
                    text: "다리가 시리거나 저리고, 피부과 치료를 받아도 자주 가렵다.",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.diagnosis-icon {
    max-width: 26px;
    margin-right: 6px;
}
@media (min-width: 1024px) {
    .diagnosis-icon {
        max-width: 36px;
        margin-right: 10px;
    }
}
</style>
