<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류 수술" tabActive="플레보그립" bg="/images/sub/surgery/visual.jpg">
                <template #title>하지정맥류 수술</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="tit--lg font-weight-regular mb-8px mb-md-16px">플레보그립</div>
                        <div class="tit--xs primary--text text--darken-4">Flebogrif (MOCA: Mechanico-Chemical Ablation)</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg">
                    <strong class="d-block mb-4px mb-md-8px">플레보그립은 원칙적으로 클라리베인과 원리가 같아 장단점이 비슷합니다.</strong>
                    <div>작용범위, 사용가능 약물 등이 달라, 환자의 체형, 하지정맥류 상태에 따라 클라리베인과 플레보그립 중 선택해서 사용할 수 있습니다.</div>
                </u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first page-section--last">
            <v-img src="/images/sub/surgery/flebogrif.png" class="border-x border-y rounded" />

            <div class="pt-40px pt-md-60px">
                <v-row>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">특징 및 장점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>정맥내요법 중 열을 사용하지 않는 비열치료법 중 하나</li>
                                <li>열에 의한 신경손상이 없음</li>
                                <li>시술 중 통증이 적어 전신마취나 척추마취 없이 수면마취로 수술이 가능</li>
                                <li>추가적인 정맥절제술이 필요 없어 흉터가 거의 없음</li>
                                <li>퇴원 후 즉시 일상 생활 가능</li>
                                <li>
                                    미국, 유럽 및 대한민국 식약처(FDA, CE, KFDA)의 안전성 시험을 <br class="d-none d-lg-block" />
                                    정식으로 거친 후 허가된 방법
                                </li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">단점 및 부작용</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>상대적으로 고가인 수술비</li>
                                <li>일시적 정맥염 (낮은 가능성)</li>
                                <li>심부정맥혈전증 (매우 희박)</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </div>

            <v-sheet rounded class="overflow-hidden mt-40px mt-md-60px">
                <v-responsive :aspect-ratio="16 / 9">
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/SaF2CGhPy-E?si=uqci_GB_1-CV28Hm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </v-responsive>
            </v-sheet>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
