<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류 수술" tabActive="베나실" bg="/images/sub/surgery/visual.jpg">
                <template #title>하지정맥류 수술</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="tit--lg font-weight-regular mb-8px mb-md-16px">베나실</div>
                        <div class="tit--xs primary--text text--darken-4">VenaSeal™ Closure System (Cyanoacrylate)</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg">
                    베나실은 최근에 도입된 치료법으로 역류가 생긴 혈관에 Cyanoacrylate(의료용 생체접착제)로 혈관을 접착 폐쇄하여 역류를 차단하는 방법입니다. <br class="d-none d-xl-block" />
                    기존 방법과 달리 물리적인 손상이나 열로 인한 손상이 없어 어떤 방법보다 수술 후 통증이 확연히 적습니다.
                </u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first">
            <v-sheet rounded outlined class="pa-20px">
                <v-img max-width="1008" src="/images/sub/surgery/venaseal.jpg" class="mx-auto" />
            </v-sheet>

            <div class="pt-40px pt-md-60px">
                <v-row>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">특징 및 장점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>정맥내요법 중 열을 사용하지 않는 비열치료법 중 하나</li>
                                <li>열에 의한 신경손상이 없음</li>
                                <li>시술 중 통증이 적어 전신마취나 척추마취 없이 수면마취로 수술이 가능</li>
                                <li>추가적인 정맥절제술이 필요 없어 흉터가 거의 없음</li>
                                <li>퇴원 후 즉시 일상 생활 가능</li>
                                <li>
                                    미국, 유럽 및 대한민국 식약처(FDA, CE, KFDA)의 안전성 시험을 <br class="d-none d-lg-block" />
                                    정식으로 거친 후 허가된 방법
                                </li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">단점 및 부작용</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>상대적으로 고가인 수술비</li>
                                <li>이물반응에 의한 일시적 알러지반응 <br class="d-sm-none">(가려움, 부종 등)</li>
                                <li>심부정맥혈전증 (매우 희박)</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </div>

            <v-sheet rounded class="primary lighten-5 mt-60px mt-md-80px pa-24px px-lg-80px py-lg-60px">
                <v-row class="row--xs">
                    <v-col cols="12" sm="6">
                        <v-img src="/images/sub/surgery/venaseal-image-01.jpg" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-img src="/images/sub/surgery/venaseal-image-02.jpg" />
                    </v-col>
                </v-row>
            </v-sheet>
        </page-section-primary>

        <page-section-primary class="page-section--xs grey lighten-5 page-section--last">
            <u-tit-wrap-default class="text-center">
                <u-tit-default class="font-secondary">
                    <div class="tit mb-8px mb-md-16px">베나실 실력이 검증된 이승철 원장님</div>
                    <div class="tit--sm font-weight-regular">베나실 수술경험이 매우 풍부하여 <br class="d-md-none">많은 노하우를 가지고 있습니다.</div>
                </u-tit-default>
            </u-tit-wrap-default>

            <u-txt-default class="txt--lg txt--dark text-center">
                <div class="mb-8px mb-md-16px">
                    베나실은 정맥 내 요법 중 가장 최신의 방법이지만, 복잡하고 정확한 기술을 필요로 하므로 <br class="d-none d-xl-block" />
                    가급적 베나실 경험이 풍부한 전문의에게 수술 받는 것이 좋습니다.
                </div>
                <div>미국 베나실 권위자 Dr.Jones가 이승철 원장님의 베나실 Live Surgery 참관하는 등, <strong>베나실의 세계적인 대가들과 함께</strong>하였습니다.</div>
            </u-txt-default>

            <v-sheet rounded class="mt-40px mt-md-60px pa-16px pa-md-40px px-xl-80px">
                <v-row justify="center" align="center">
                    <v-col cols="12" lg="auto">
                        <v-img max-width="276" src="/images/main/professionalism-1-2.png" class="mx-auto" />
                    </v-col>
                    <v-col>
                        <u-tit-default class="tit--sm text-center text-lg-start font-weight-regular line-height-17">
                            편안하지 흉부외과는 <strong>2020년 10월</strong> <br class="d-lg-none d-xl-block" />
                            VenaSeal 수술 <strong class="primary--text text--darken-4">1,000례(환자기준)</strong>를 <br class="d-lg-none d-xl-block" />
                            (주)메드트로닉코리아로부터 공식 <br class="d-lg-none d-xl-block" />
                            확인받았습니다.
                        </u-tit-default>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-sheet color="transparent" rounded class="mt-60px mt-md-80px">
                <v-row class="row--xs">
                    <v-col cols="12" md="6">
                        <v-img src="/images/sub/surgery/venaseal-interact-01.jpg" class="rounded" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-img src="/images/sub/surgery/venaseal-interact-02.jpg" class="rounded" />
                    </v-col>
                    <v-col cols="12">
                        <video controls src="/videos/venaseal.mp4" class="w-100 rounded"></video>
                    </v-col>
                </v-row>
            </v-sheet>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
