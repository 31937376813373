<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류" tabActive="하지정맥류 치료원칙" bg="/images/sub/varicose/visual.jpg">
                <template #title>하지정맥류</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-default class="font-secondary">
                    <div class="tit--xs primary--text text--darken-4 mb-8px mb-md-16px">Treatment Principles</div>
                    <div class="tit--lg font-weight-regular">하지정맥류 치료 원칙</div>
                </u-tit-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first">
            <div class="principle-tree">
                <!-- S: Mobile Tree Top -->
                <v-sheet rounded color="grey lighten-5" class="overflow-hidden d-xl-none mb-24px mb-md-48px">
                    <v-sheet color="primary lighten-3" class="px-16px py-24px py-md-48px text-center font-secondary">
                        <u-tit-default>
                            <div class="tit--lg primary--text text--darken-4 mb-8px mb-md-16px">비수술적 방법</div>
                            <div class="tit--xs font-weight-regular grey--text text--darken-4">보존적 방법</div>
                        </u-tit-default>
                    </v-sheet>
                    <div class="pa-16px pa-md-48px text-center">
                        <u-tit-default class="tit--xs font-weight-regular">
                            <div>
                                근본적인 치료는 되지 않고, <br />
                                증상을 조절하는 방법
                            </div>
                            <div class="primary--text mt-8px">수술이 싫거나, <br class="d-md-none" />수술을 할 수 없을 때 사용</div>
                        </u-tit-default>
                    </div>
                </v-sheet>
                <!-- E: Mobile Tree Top -->

                <div class="principle-tree__btn-group">
                    <v-row class="row--xxs">
                        <v-col cols="4">
                            <v-card to="/non-surgical/stockings" flat rounded="pill" color="primary" class="font-secondary principle-tree__btn">
                                <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
                                    <v-card-title class="pa-0 justify-center flex-column white--text font-size-12 font-size-md-24 font-size-xl-16">
                                        <div>압박스타킹</div>
                                        <v-icon class="font-weight-regular white--text mt-4px mt-md-8px">mdi-plus</v-icon>
                                    </v-card-title>
                                </v-responsive>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card to="/non-surgical/drugs" flat rounded="pill" color="primary" class="font-secondary principle-tree__btn">
                                <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
                                    <v-card-title class="pa-0 justify-center flex-column white--text font-size-12 font-size-md-24 font-size-xl-16">
                                        <div>경구약</div>
                                        <v-icon class="font-weight-regular white--text mt-4px mt-md-8px">mdi-plus</v-icon>
                                    </v-card-title>
                                </v-responsive>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card to="/non-surgical/sclerotherapy" flat rounded="pill" color="primary" class="font-secondary principle-tree__btn">
                                <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
                                    <v-card-title class="pa-0 justify-center flex-column white--text font-size-12 font-size-md-24 font-size-xl-16">
                                        <div>혈관경화요법</div>
                                        <v-icon class="font-weight-regular white--text mt-4px mt-md-8px">mdi-plus</v-icon>
                                    </v-card-title>
                                </v-responsive>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>

                <!-- S: Mobile Tree Bottom -->
                <v-sheet rounded color="grey lighten-5" class="overflow-hidden d-xl-none my-24px my-md-48px">
                    <v-sheet color="primary lighten-3" class="px-16px py-24px py-md-48px text-center font-secondary">
                        <u-tit-default>
                            <div class="tit--lg primary--text text--darken-4 mb-8px mb-md-16px">수술적 방법</div>
                            <div class="tit--xs font-weight-regular grey--text text--darken-4">근본 치료 방법</div>
                        </u-tit-default>
                    </v-sheet>
                    <div class="pa-16px pa-md-48px text-center">
                        <v-card outlined flat rounded color="primary" class="pa-24px pa-md-48px">
                            <u-tit-default class="text-center">
                                <div class="tit--lg mb-8px mb-md-14px">외과적 제거</div>
                                <div class="tit--xs primary--text font-weight-regular mb-16px mb-md-32px">고위결찰술 및 발거술</div>
                                <div class="tit--xs grey--text font-weight-regular">
                                    치료 대상 혈관을 피부 절개 후 <br />
                                    물리적으로 잡아당겨 제거하는 <br />
                                    전통적 방법
                                </div>
                            </u-tit-default>
                        </v-card>
                        <v-card outlined flat rounded color="primary" class="mt-16px mt-md-32px pa-24px pa-md-48px">
                            <u-tit-default class="text-center">
                                <div class="tit--lg mb-8px mb-md-14px">정맥내 제거 수술</div>
                                <div class="tit--xs primary--text font-weight-regular mb-16px mb-md-32px">
                                    클라리베인 <br />
                                    베나실 <br />
                                    플레보그립 <br />
                                    레이저 <br />
                                    고주파
                                </div>
                                <div class="tit--xs grey--text font-weight-regular">
                                    치료 대상 혈관을 내시경 처럼 <br />
                                    혈관 안에서 치료하는 방법
                                </div>
                            </u-tit-default>
                        </v-card>
                    </div>
                </v-sheet>

                <v-sheet rounded color="#FF6D05" class="d-xl-none px-30px px-md-60px py-24px py-md-48px">
                    <u-txt-default class="font-size-md-28px font-weight-bold white--text text-center">
                        현재 미국, 유럽 등에서는 ‘정맥내요법’을 <br />
                        하지정맥류의 표준치료법으로 제시하고 있습니다.
                    </u-txt-default>
                </v-sheet>
                <!-- E: Mobile Tree Bottom -->

                <!-- PC Background -->
                <v-img src="/images/sub/varicose/principle-tree.png" class="d-none d-xl-block principle-tree__bg" />
            </div>
        </page-section-primary>

        <page-section-primary class="page-section--xs page-section--last grey lighten-5">
            <u-tit-wrap-default class="text-center">
                <u-tit-default class="font-secondary font-weight-regular">미국 및 영국 <strong>정맥학회 지침</strong></u-tit-default>
            </u-tit-wrap-default>

            <v-row class="row--sm">
                <v-col cols="12" md="6">
                    <u-tit-default class="tit--sm font-secondary font-weight-regular mb-16px mb-md-24px">미국 정맥학회 지침</u-tit-default>
                    <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-40px" />

                    <v-row class="row--xs">
                        <v-col cols="12">
                            <u-txt-default>
                                <strong class="txt--lg txt--dark d-block mb-16px mb-md-24px">Phlebology 2012;27 Suppl 1:2-9.</strong>
                                <div>(7) For treatment of the incompetent GSV, endovenous thermal ablation (radiofrequency, RF, of endovenous laser therapy, EVLT) is recommended over high ligation and stripping of the saphenous vein. Recommendation: GRADE 1 (strong), level of evidence: B (medium queality)</div>
                                <div class="txt--dark font-weight-medium mt-4px mt-md-8px">(7) 하지정맥류의 치료는 정맥내치료(고주파, 레이저 등)가 고위결찰발거술보다 더 우선적으로 추천된다. (강력한 추천 등급)</div>
                            </u-txt-default>
                        </v-col>
                        <v-col cols="12">
                            <v-img src="/images/sub/varicose/guide-us-01.jpg" class="rounded" />
                        </v-col>
                        <v-col cols="12">
                            <v-img src="/images/sub/varicose/guide-us-02.jpg" class="rounded" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                    <u-tit-default class="tit--sm font-secondary font-weight-regular mb-16px mb-md-24px">영국 정맥학회 지침</u-tit-default>
                    <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-40px" />

                    <v-row class="row--xs">
                        <v-col cols="12">
                            <u-txt-default>
                                <strong class="txt--lg txt--dark d-block mb-16px mb-md-24px">NICE guideline :Varicose veins in the legs 2013 10-11p</strong>
                                <div class="mb-4px mb-md-8px">Interventional treatment</div>
                                <div class="txt--dark font-weight-medium">하지정맥류를 수술할 경우</div>
                                <ul class="dot-list dot-list--sm mt-16px mt-md-24px">
                                    <li>Offer endothermal ablation (RFA) and EVLT</li>
                                    <li class="txt--dark font-weight-medium">고주파나 레이저 같은 정맥내 요법으로 수술하세요.</li>
                                    <li>If endothermal ablation is unsuitable, offer ultrasound-guided foam sclerotherapy</li>
                                    <li class="txt--dark font-weight-medium">이런 정맥내 요법이 안되는 경우, 약물요법(경화제)으로 치료하세요.</li>
                                    <li>If ultrasound-guided foam sclerotherapy is unsuitable, offer surgery</li>
                                    <li class="txt--dark font-weight-medium">그리고 이러한 약물요법도 안되는 경우 발거술을 시행하세요.</li>
                                </ul>
                            </u-txt-default>
                        </v-col>
                        <v-col cols="12">
                            <v-img src="/images/sub/varicose/guide-uk-01.jpg" class="rounded" />
                        </v-col>
                        <v-col cols="12">
                            <v-img src="/images/sub/varicose/guide-uk-02.jpg" class="rounded" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.principle-tree {
    position: relative;
    &__btn-group {
        padding: 20px;
        border: 1px solid var(--v-primary-base);
        border-radius: 999px;
    }
    &__btn {
        position: relative;
        .v-card__title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -25%);
        }
    }
}
@media (min-width: 768px) {
    .principle-tree {
        &__btn-group {
            padding: 40px;
        }
    }
}
@media (min-width: 1200px) {
    .principle-tree {
        aspect-ratio: 1200 / 616;
        &__bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        &__btn-group {
            padding: 0;
            border: none;
            width: calc(392 / 1200 * 100%);
            position: absolute;
            bottom: calc(90 / 616 * 100%);
            left: calc(89 / 1200 * 100%);
            z-index: 5;
        }

        &__btn {
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.2);
                opacity: 0;
                transition: all ease-out 0.2s;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}
</style>
