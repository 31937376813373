<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류" tabActive="하지정맥류치료기준 (조건)" bg="/images/sub/varicose/visual.jpg">
                <template #title>하지정맥류</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-default class="font-secondary">
                    <div class="tit--xs primary--text text--darken-4 mb-8px mb-md-16px">Treatment Standards</div>
                    <div class="tit--lg font-weight-regular">하지정맥류 치료 기준</div>
                </u-tit-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first">
            <u-txt-default class="txt--lg txt--dark text-center mb-16px mb-md-24px"> 하지정맥류는 대표적인 <strong>’양성질환’</strong>으로 <br class="d-md-none"> <strong class="primary--text">“근본적으로 없애고 싶은 증상이 있는 경우”</strong>에 <br class="d-md-none">진단과 치료를 하는 질병입니다. </u-txt-default>

            <u-tit-wrap-default class="tit-wrap--lg">
                <v-row justify="center">
                    <v-col cols="12" xl="10">
                        <v-sheet rounded="sm" color="grey lighten-5" class="px-8px py-16px text-center" style="border: 1px solid var(--v-grey-lighten3) !important">
                            <u-txt-default>
                                <div>양성질환 : 특별한 경우가 아닌 경우 <br class="d-sm-none"> 사망에 까지 이르지 않는 질환</div>
                                <div class="mt-4px">악성질환 : 치료하지 않는 경우 <br class="d-sm-none">
                                사망에 이를 확률이 높은 질환 <br class="d-md-none">(예 : 심장병, 암 등)</div>
                            </u-txt-default>
                        </v-sheet>
                    </v-col>
                </v-row>
            </u-tit-wrap-default>

            <u-txt-default class="txt--lg txt--dark text-center mb-24px mb-md-40px">
                하지정맥류의 치료 기준은 <strong>다음과 같은 3가지 경우에 ‘수술적 근본치료’의 대상</strong>이 됩니다. <br />
                <strong>전제 조건은 ‘초음파 상에서 해당 혈관의 역류가 관찰’</strong>되어야 합니다.
            </u-txt-default>

            <v-card outlined flat color="primary" class="rounded-xl rounded-md-pill px-24px px-md-130px py-40px py-md-32px mb-24px mb-md-40px">
                <v-row class="row--sm">
                    <v-col cols="12" md="6">
                        <v-card flat height="44" rounded="pill" color="primary" class="d-flex justify-center align-center mb-8px mb-md-16px">
                            <u-tit-default class="tit--xs"> 필수 </u-tit-default>
                        </v-card>
                        <u-txt-default class="font-weight-bold secondary--text text-center">
                            <p>01. 혈전이 있거나, 혈전에 의한 정맥염이 있는 경우</p>
                            <p>02. 피부병변(습진, 착색, 궤양, 지방피부경화증 등)이 동반되는 경우</p>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card flat height="44" rounded="pill" color="primary" class="d-flex justify-center align-center mb-8px mb-md-32px">
                            <u-tit-default class="tit--xs">상대적</u-tit-default>
                        </v-card>
                        <u-txt-default class="font-weight-bold secondary--text text-center"> 03. 없애고 싶은 불편한 증상이 있는 경우 </u-txt-default>
                    </v-col>
                </v-row>
            </v-card>

            <v-row class="row--xs">
                <v-col cols="6">
                    <v-img src="/images/sub/varicose/standard-01.png" />
                </v-col>
                <v-col cols="6">
                    <v-img src="/images/sub/varicose/standard-02.jpg" class="rounded" />
                </v-col>
            </v-row>
        </page-section-primary>

        <page-section-primary class="page-section--xs page-section--last grey lighten-5">
            <u-tit-wrap-default class="text-center">
                <u-tit-default class="font-secondary font-weight-regular">하지정맥류 수술적 치료의 적응증</u-tit-default>
            </u-tit-wrap-default>

            <v-sheet rounded class="pa-24px pa-md-40px">
                <v-row align="center" class="row--xs">
                    <v-col cols="12" md="" order-md="2">
                        <u-tit-default class="tit--sm mb-24px mb-md-40px">The indicaiton of surgical treatment for varicose veins</u-tit-default>
                        <u-txt-default>
                            <div class="mb-16px mb-md-24px">
                                Surgical treatment for varicose veins is usually considered when other, <br class="d-none d-xl-block" />
                                less invasive treatments have failed or are not suitable. The indications for considering surgical <br class="d-none d-xl-block" />
                                intervention include:
                            </div>
                            <v-row class="row--xxs">
                                <v-col cols="12"><strong>1.Symptomatic Varicose Veins:</strong> Persistent symptoms such as pain, swelling, leg heaviness, and cramping that significantly affect quality of life.</v-col>
                                <v-col cols="12"><strong>2.Complications:</strong> Development of complications from varicose veins, such as skin changes (e.g., eczema, pigmentation), lipodermatosclerosis (hardening of skin and fat beneath the skin), venous ulcers, or bleeding from the varicose veins.</v-col>
                                <v-col cols="12"><strong>3.Venous Insufficiency:</strong> Severe chronic venous insufficiency, which can lead to more serious health issues if not treated.</v-col>
                                <v-col cols="12"><strong>4.Thrombophlebitis:</strong> Recurrent or persistent superficial thrombophlebitis, which is an inflammatory process causing a blood clot to form and block one or more veins, usually in the legs.</v-col>
                                <v-col cols="12"><strong>5.Aesthetic Concerns:</strong> In some cases, patients might seek surgical treatment for varicose veins primarily due to aesthetic concerns, although this is typically considered only after evaluating the potential benefits and risks</v-col>
                            </v-row>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12">
                        <v-img max-width="792" src="/images/sub/varicose/adaptation.jpg" class="mx-auto" />
                    </v-col>
                </v-row>
            </v-sheet>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
    .rounded-md-pill {
        border-radius: 999px;
    }
}
</style>
