var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "편안하지흉부외과",
            "tabActive": "진료시간/오시는 길",
            "bg": "/images/sub/introduction/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("편안하지흉부외과")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--lg text-center"
  }, [_c('u-tit-default', {
    staticClass: "font-secondary"
  }, [_c('div', {
    staticClass: "tit--xs primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("Clinic hours & Location")]), _c('div', {
    staticClass: "tit--lg font-weight-regular"
  }, [_vm._v("진료시간 & 오시는 길")])])], 1), _c('page-section-primary', {
    staticClass: "page-section--sm page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('iframe', {
    staticClass: "h-md-100 rounded",
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.6854712576!2d127.10040047642867!3d37.51533582706148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca50b6af4ec7b%3A0x3fe9b6c3d5770a8e!2z7Y647JWI7ZWY7KeA7Z2J67aA7Jm46rO87J2Y7JuQ!5e0!3m2!1sko!2skr!4v1712993619826!5m2!1sko!2skr",
      "width": "100%",
      "height": "218",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-16px pa-md-40px",
    attrs: {
      "rounded": "",
      "color": "secondary"
    }
  }, [_c('u-tit-default', {
    staticClass: "font-secondary mb-8px mb-md-16px"
  }, [_vm._v("진료안내")]), _c('v-divider'), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18 txt--light"
  }, [_c('ul', {
    staticClass: "pt-8px pt-md-16px"
  }, [_c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "3",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-130px"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-phone-primary.svg"
    }
  })], 1), _c('v-col', [_vm._v("대표전화")])], 1)], 1)]), _c('v-col', [_vm._v("02-6424-7979")])], 1)], 1), _c('li', {
    staticClass: "pt-24px pt-md-32px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "3",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-130px"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-clock.svg"
    }
  })], 1), _c('v-col', [_vm._v("진료시간")])], 1)], 1)]), _c('v-col', [_c('ul', [_c('li', [_vm._v("월요일-금요일 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("AM 09 : 00 - PM 06 : 00")]), _c('li', {
    staticClass: "pt-4px"
  }, [_vm._v("토요일 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("AM 09 : 00 - PM 04 : 00 "), _c('br'), _vm._v("(토요일은 점심시간이 없습니다)")]), _c('li', {
    staticClass: "pt-4px"
  }, [_vm._v("점심시간 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("PM 01 : 00 - PM 02 : 00")]), _c('li', [_vm._v("일요일 / 공휴일 휴진")])])])], 1)], 1)])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-16px pa-md-40px",
    attrs: {
      "rounded": "",
      "color": "grey lighten-5"
    }
  }, [_c('u-tit-default', {
    staticClass: "font-secondary mb-8px mb-md-16px"
  }, [_vm._v("오시는 길")]), _c('v-divider', {
    staticStyle: {
      "border-color": "#000"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--lg font-size-md-18"
  }, [_c('ul', {
    staticClass: "pt-8px pt-md-16px"
  }, [_c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "3",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-130px"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-map.svg"
    }
  })], 1), _c('v-col', [_vm._v("위치")])], 1)], 1)]), _c('v-col', [_vm._v("서울시 송파구 올림픽로 35다길 42 루터회관 601호")])], 1)], 1), _c('li', {
    staticClass: "pt-16px pt-md-20px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "3",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-130px"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-bus.svg"
    }
  })], 1), _c('v-col', [_vm._v("교통버스")])], 1)], 1)]), _c('v-col', [_c('ul', [_c('li', [_vm._v("잠실역 / 잠실역 중앙차로에서 하차")]), _c('li', {
    staticClass: "pt-4px"
  }, [_c('ul', {
    staticClass: "dot-list dot-list--sm"
  }, [_c('li', [_vm._v("30-3, 30-5, 31, 32, 35, 101, 119, 2000-2")]), _c('li', {
    staticClass: "mt-4px"
  }, [_vm._v("301, 302, 303, 320, 341, 345, 360, N13, N32, N34")]), _c('li', {
    staticClass: "mt-4px"
  }, [_vm._v("2311, 2412, 2415, 3215, 3216, 3217, 3313, 3314, 3315, 3317, 3319, 3411, 3413, 3414, 4319")]), _c('li', {
    staticClass: "mt-4px"
  }, [_vm._v("강남05")])])])])])], 1)], 1), _c('li', {
    staticClass: "pt-16px pt-md-20px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "3",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-130px"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-subway.svg"
    }
  })], 1), _c('v-col', [_vm._v("지하철")])], 1)], 1)]), _c('v-col', [_c('span', {
    staticClass: "line-2"
  }, [_vm._v("2")]), _vm._v(", "), _c('span', {
    staticClass: "line-8"
  }, [_vm._v("8")]), _vm._v("호선 잠실역(송파구청) 8, 9번 출구 사이, 루터회관 6층")])], 1)], 1), _c('li', {
    staticClass: "pt-16px pt-md-20px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "3",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-130px"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-car.svg"
    }
  })], 1), _c('v-col', [_vm._v("승용차")])], 1)], 1)]), _c('v-col', [_vm._v(" 네비게이션에 '루터회관' 입력 "), _c('br'), _vm._v(" 루터회관 주차장 "), _c('br'), _vm._v(" (지하 2층부터 지하 5층까지 무료이용 가능) ")])], 1)], 1)])])], 1)], 1)], 1)], 1)], 1), _c('iframe', {
    staticClass: "rounded mt-24px mt-md-60px",
    attrs: {
      "width": "100%",
      "src": "https://www.youtube.com/embed/9B2kklMoRYI?si=gkkHOk4NYbBwqbU8",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }