<template>
    <u-header-basic class="header--lnb-all">
        <!-- S: Header -->
        <u-header-body-basic class="d-none d-xl-block">
            <template #left>
                <v-row align="center" class="row--xxs">
                    <v-col cols="auto">
                        <logo-primary :class="logoClass">편안하지흉부외과</logo-primary>
                    </v-col>
                    <v-col cols="auto">
                        <v-card height="34" rounded="pill" color="secondary" class="d-flex align-center px-18px">
                            <v-img eager max-width="16" src="/images/icon/icon-phone.svg" class="mr-6px"></v-img>
                            <u-tit-default class="tit--xs">02-6424-7979</u-tit-default>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template #right>
                <u-gnb-basic class="text-center" gnbName="gnb" lnbTag="u-lnb-all-opacity-black" />
            </template>
        </u-header-body-basic>
        <!-- E: Header -->

        <!-- S: Sitemap -->
        <sitemap-primary />
        <!-- E: Sitemap -->
    </u-header-basic>
</template>

<script>
import LogoPrimary from "@/components/publish/parents/headers/logo-primary.vue";
import UserItemsPrimary from "@/components/publish/parents/headers/user-items-primary.vue";
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";
import SitemapPrimary from "@/sets/parents/headers/sitemap-primary.vue";

import UHeaderBasic from "@/sets/styles/headers/u-header-basic.vue";
import UHeaderFixed from "@/sets/styles/headers/u-header-fixed.vue";
import UHeaderBodyBasic from "@/sets/styles/headers/u-header-body-basic.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";

import Gnb from "@/components/publish/styles/headers/gnb.vue";
import UGnbBasic from "@/components/publish/styles/headers/u-gnb-basic.vue";
import UGnbFilled from "@/components/publish/styles/headers/u-gnb-filled.vue";
import UGnbTopline from "@/components/publish/styles/headers/u-gnb-topline.vue";
import UGnbUnderline from "@/components/publish/styles/headers/u-gnb-underline.vue";

export default {
    components: {
        LogoPrimary,
        UserItemsPrimary,
        BtnHamburgerPrimary,
        SitemapPrimary,

        UHeaderBasic,
        UHeaderFixed,
        UHeaderBodyBasic,
        UTitDefault,

        Gnb,
        UGnbBasic,
        UGnbFilled,
        UGnbTopline,
        UGnbUnderline,
    },
    mounted() {},
    methods: {
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    ::v-deep {
        .logo {
            > a {
                background-image: url(/images/logo-dark.svg);
            }
        }
        .gnb--basic .gnb__link {
            color: var(--v-grey-darken4) !important;
        }
    }
}
</style>
