<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류 수술" tabActive="고주파" bg="/images/sub/surgery/visual.jpg">
                <template #title>하지정맥류 수술</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="tit--lg font-weight-regular mb-8px mb-md-16px">고주파 수술</div>
                        <div class="tit--xs primary--text text--darken-4">RFA : RadioFrequency Ablation</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg">
                    고주파가 발생하는 카테터를 정맥 내부에 삽입한 후 고주파 열로 정맥을 위축시켜 치료하는 방법입니다. <br class="d-none d-xl-block" />
                    수술과정은 레이저 수술과 비슷하지만 수술 후 통증이나 멍드는 현상이 훨씬 적어 회복이 빠릅니다.
                </u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first page-section--last">
            <v-sheet rounded outlined class="pa-20px py-md-68px">
                <v-img max-width="588" src="/images/sub/surgery/rfa.jpg" class="mx-auto" />
            </v-sheet>

            <v-sheet rounded class="primary lighten-5 mt-40px mt-md-60px pa-24px px-lg-80px py-lg-60px">
                <v-row class="row--xs">
                    <v-col cols="12" sm="auto">
                        <v-img max-width="528" src="/images/sub/surgery/rfa-image-01.png" />
                    </v-col>
                    <v-col cols="12" sm="">
                        <v-img max-width="488" src="/images/sub/surgery/rfa-image-02.jpg" />
                    </v-col>
                </v-row>
            </v-sheet>

            <div class="pt-40px pt-md-60px">
                <v-row>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">특징 및 장점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>레이저치료의 장점은 유지하면서 단점을 보완한 방법</li>
                                <li>
                                    방출되는 열이 120도로, 레이저보다 현저히 낮아 열에 의한 <br class="d-none d-lg-block" />
                                    부작용 가능성이 더 낮음
                                </li>
                                <li>레이저에 비해 수술 후 통증이 적음</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">단점 및 부작용</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>압박스타킹 최소 2주간 착용</li>
                                <li>통증 및 신경손상 가능 (레이저보다 현저히 낮지만 가능성이 있음)</li>
                                <li>일시적 정맥염</li>
                                <li>심부정맥혈전증 (매우 희박)</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </div>

            <v-sheet rounded class="grey lighten-5 mt-60px mt-md-80px pa-16px py-md-60px">
                <v-row justify="center" class="row--xs">
                    <v-col cols="6" md="auto">
                        <v-img max-width="322" src="/images/sub/introduction/Certified-02.png" class="mx-auto border-x border-y" />
                        <u-txt-default class="txt--sm mt-10px text-center">
                            RFA(고주파) 수술의 <br><strong>교육자문위원</strong>으로 <br />
                            위촉된 이승철 원장
                        </u-txt-default>
                    </v-col>
                    <v-col cols="6" md="auto">
                        <v-img max-width="320" src="/images/sub/introduction/Certified-04.png" class="mx-auto" />
                        <u-txt-default class="txt--sm font-size-md-18 mt-10px text-center">편안하지흉부외과 <br><strong>고주파 교육기관 선정</strong> </u-txt-default>
                    </v-col>
                </v-row>
            </v-sheet>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
