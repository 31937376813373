<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="하지정맥류 수술" tabActive="레이저" bg="/images/sub/surgery/visual.jpg">
                <template #title>하지정맥류 수술</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm text-center">
                    <u-tit-default class="font-secondary">
                        <div class="tit--lg font-weight-regular mb-8px mb-md-16px">레이저 수술</div>
                        <div class="tit--xs primary--text text--darken-4">EVLT : Endovenous LASER Treatment</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg"> 문제가 있는 정맥 안에 레이저 광섬유를 삽입한 후 레이저 에너지를 통해 정맥의 내막을 태워 혈관을 수축시켜 막는 방법입니다. </u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--xs page-section--first">
            <v-sheet rounded outlined class="pa-20px py-md-68px">
                <v-img max-width="526" src="/images/sub/surgery/laser.jpg" class="mx-auto" />
            </v-sheet>

            <v-sheet rounded class="primary lighten-5 mt-40px mt-md-60px pa-24px px-lg-80px py-lg-60px">
                <v-row class="row--xs">
                    <v-col cols="12" sm="6">
                        <v-img max-width="508" src="/images/sub/surgery/laser-image-01.png" class="mx-auto" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-img max-width="508" src="/images/sub/surgery/laser-image-02.jpg" class="mx-auto" />
                    </v-col>
                </v-row>
            </v-sheet>

            <div class="pt-40px pt-md-60px">
                <v-row>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">특징 및 장점</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>수술부위 절개가 없어 흉터 및 수술 후 통증이, 발거술 보다 현저히 낮음</li>
                                <li>15년 이상 사용으로 축적된 경험이 많음</li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <u-tit-default class="tit--sm font-weight-regular font-secondary mb-8px mb-md-16px">단점 및 부작용</u-tit-default>
                        <v-divider style="border-color: var(--v-grey-darken4)" class="mb-24px mb-md-40px" />
                        <u-txt-default class="txt--lg font-size-md-18">
                            <ul class="square-list">
                                <li>
                                    고온(800-1200도 이상)을 사용하기 때문에 이론적으로는 열에 의한 <br class="d-none d-lg-block" />
                                    주변 조직 손상 등의 부작용이 발생할 수 있음
                                </li>
                                <li>하지정맥류가 많이 진행되어 정맥이 너무 많이 구불구불한 경우 시행이 어려움</li>
                                <li>
                                    레이저 수술법의 단점을 보완한 새로운 수술법들이 많이 개발되어<br class="d-none d-lg-block" />
                                    상대적 약점이 있음
                                </li>
                            </ul>
                        </u-txt-default>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
