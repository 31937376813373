<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="편안하지흉부외과" tabActive="병원 둘러보기" bg="/images/sub/introduction/visual.jpg">
                <template #title>편안하지흉부외과</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap--sm">
                    <u-tit-default class="font-secondary">
                        <div class="tit--xs primary--text text--darken-4 mb-8px mb-md-16px">Introduction</div>
                        <div class="tit--lg font-weight-regular">병원 둘러보기</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg">
                    편안하지흉부외과는 환자분들께 더욱 쾌적하고 편안한 진료환경을 제공하고자 노력하고 있습니다. <br class="d-none d-xl-block" />
                    환자분의 증상이 좋아질 때까지 철저한 관리를 약속드리겠습니다.
                </u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--sm page-section--first page-section--last">
            <v-tabs v-model="tab" grow hide-slider class="flex-1 mb-24px mb-md-36px">
                <tab-quarternary>입구 및 접수데스크</tab-quarternary>
                <tab-quarternary>진료대기실</tab-quarternary>
                <tab-quarternary>진료실 및 상담실</tab-quarternary>
                <tab-quarternary>입원실</tab-quarternary>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <div class="main-slide main-slide--desk">
                        <swiper :options="deskOptions">
                            <template v-for="item in desk">
                                <swiper-slide :key="item">
                                    <img :src="item.src" alt="" class="w-100 rounded d-block" />
                                </swiper-slide>
                            </template>

                            <div class="swiper-control" slot="pagination" @click.prevent>
                                <v-row no-gutters justify="space-between" align="center">
                                    <v-col cols="auto">
                                        <v-btn color="primary darken-4" outlined icon class="swiper-button-prev v-size--xx-large">
                                            <v-icon class="primary--text">chevron_left</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn color="primary darken-4" outlined icon class="swiper-button-next v-size--xx-large">
                                            <v-icon class="primary--text">chevron_right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </swiper>
                    </div>

                    <div class="thumbs-slide thumbs-slide--desk">
                        <swiper :options="thumbs">
                            <template v-for="item in desk">
                                <swiper-slide :key="item">
                                    <img :src="item.src" alt="" class="w-100 rounded-sm d-block" />
                                </swiper-slide>
                            </template>
                        </swiper>
                    </div>
                </v-tab-item>

                <v-tab-item>
                    <div class="main-slide main-slide--waiting">
                        <swiper :options="waitingOptions">
                            <template v-for="item in waiting">
                                <swiper-slide :key="item">
                                    <img :src="item.src" alt="" class="w-100 rounded d-block" />
                                </swiper-slide>
                            </template>

                            <div class="swiper-control" slot="pagination" @click.prevent>
                                <v-row no-gutters justify="space-between" align="center">
                                    <v-col cols="auto">
                                        <v-btn color="primary darken-4" outlined icon class="swiper-button-prev v-size--xx-large">
                                            <v-icon class="primary--text">chevron_left</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn color="primary darken-4" outlined icon class="swiper-button-next v-size--xx-large">
                                            <v-icon class="primary--text">chevron_right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </swiper>
                    </div>

                    <div class="thumbs-slide thumbs-slide--waiting">
                        <swiper :options="thumbs">
                            <template v-for="item in waiting">
                                <swiper-slide :key="item">
                                    <img :src="item.src" alt="" class="w-100 rounded-sm d-block" />
                                </swiper-slide>
                            </template>
                        </swiper>
                    </div>
                </v-tab-item>

                <v-tab-item>
                    <div class="main-slide main-slide--office">
                        <swiper :options="officeOptions">
                            <template v-for="item in office">
                                <swiper-slide :key="item">
                                    <img :src="item.src" alt="" class="w-100 rounded d-block" />
                                </swiper-slide>
                            </template>

                            <div class="swiper-control" slot="pagination" @click.prevent>
                                <v-row no-gutters justify="space-between" align="center">
                                    <v-col cols="auto">
                                        <v-btn color="primary darken-4" outlined icon class="swiper-button-prev v-size--xx-large">
                                            <v-icon class="primary--text">chevron_left</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn color="primary darken-4" outlined icon class="swiper-button-next v-size--xx-large">
                                            <v-icon class="primary--text">chevron_right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </swiper>
                    </div>

                    <div class="thumbs-slide thumbs-slide--office">
                        <swiper :options="thumbs">
                            <template v-for="item in office">
                                <swiper-slide :key="item">
                                    <img :src="item.src" alt="" class="w-100 rounded-sm d-block" />
                                </swiper-slide>
                            </template>
                        </swiper>
                    </div>
                </v-tab-item>

                <v-tab-item>
                    <div class="main-slide main-slide--room">
                        <swiper :options="roomOptions">
                            <template v-for="item in room">
                                <swiper-slide :key="item">
                                    <img :src="item.src" alt="" class="w-100 rounded d-block" />
                                </swiper-slide>
                            </template>

                            <div class="swiper-control" slot="pagination" @click.prevent>
                                <v-row no-gutters justify="space-between" align="center">
                                    <v-col cols="auto">
                                        <v-btn color="primary darken-4" outlined icon class="swiper-button-prev v-size--xx-large">
                                            <v-icon class="primary--text">chevron_left</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn color="primary darken-4" outlined icon class="swiper-button-next v-size--xx-large">
                                            <v-icon class="primary--text">chevron_right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </swiper>
                    </div>

                    <div class="thumbs-slide thumbs-slide--room">
                        <swiper :options="thumbs">
                            <template v-for="item in room">
                                <swiper-slide :key="item">
                                    <img :src="item.src" alt="" class="w-100 rounded-sm d-block" />
                                </swiper-slide>
                            </template>
                        </swiper>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import TabQuarternary from "@/components/publish/parents/tabs/tab-quarternary.vue";

const desk = [
    //
    { src: "/images/sub/introduction/panorama/desk/desk-01.jpg" },
    { src: "/images/sub/introduction/panorama/desk/desk-02.jpg" },
    { src: "/images/sub/introduction/panorama/desk/desk-03.jpg" },
    { src: "/images/sub/introduction/panorama/desk/desk-04.jpg" },
    { src: "/images/sub/introduction/panorama/desk/desk-05.jpg" },
];
const waiting = [
    //
    { src: "/images/sub/introduction/panorama/waiting/waiting-01.jpg" },
    { src: "/images/sub/introduction/panorama/waiting/waiting-02.jpg" },
    { src: "/images/sub/introduction/panorama/waiting/waiting-03.jpg" },
    { src: "/images/sub/introduction/panorama/waiting/waiting-04.jpg" },
    { src: "/images/sub/introduction/panorama/waiting/waiting-05.jpg" },
    { src: "/images/sub/introduction/panorama/waiting/waiting-06.jpg" },
    { src: "/images/sub/introduction/panorama/waiting/waiting-07.jpg" },
];
const office = [
    //
    { src: "/images/sub/introduction/panorama/office/office-01.jpg" },
    { src: "/images/sub/introduction/panorama/office/office-02.jpg" },
    { src: "/images/sub/introduction/panorama/office/office-03.jpg" },
];
const room = [
    //
    { src: "/images/sub/introduction/panorama/room/room-01.jpg" },
    { src: "/images/sub/introduction/panorama/room/room-02.jpg" },
    { src: "/images/sub/introduction/panorama/room/room-03.jpg" },
    { src: "/images/sub/introduction/panorama/room/room-04.jpg" },
    { src: "/images/sub/introduction/panorama/room/room-05.jpg" },
    { src: "/images/sub/introduction/panorama/room/room-06.jpg" },
];

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
        TabQuarternary,
    },
    data() {
        return {
            tab: null,
            desk,
            waiting,
            office,
            room,

            deskOptions: {
                slidesPerView: 1,
                spaceBetween: 50,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                navigation: {
                    nextEl: ".main-slide--desk .swiper-button-next",
                    prevEl: ".main-slide--desk .swiper-button-prev",
                },
                breakpoints: {
                    768: {
                        spaceBetween: 100,
                    },
                },
                thumbs: {
                    swiper: {
                        el: ".thumbs-slide--desk .swiper-container",
                        slideToClickedSlide: true,
                        slidesPerView: "4",
                    },
                },
            },
            thumbs: {
                allowTouchMove: true,
                spaceBetween: 8,
                slideToClickedSlide: true,
                slidesPerView: "4",
                breakpoints: {
                    768: {
                        spaceBetween: 16,
                    },
                },
            },

            waitingOptions: {
                slidesPerView: 1,
                spaceBetween: 50,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                navigation: {
                    nextEl: ".main-slide--waiting .swiper-button-next",
                    prevEl: ".main-slide--waiting .swiper-button-prev",
                },
                breakpoints: {
                    768: {
                        spaceBetween: 100,
                    },
                },
                thumbs: {
                    swiper: {
                        el: ".thumbs-slide--waiting .swiper-container",
                        slideToClickedSlide: true,
                        slidesPerView: "4",
                    },
                },
            },

            officeOptions: {
                slidesPerView: 1,
                spaceBetween: 50,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                navigation: {
                    nextEl: ".main-slide--office .swiper-button-next",
                    prevEl: ".main-slide--office .swiper-button-prev",
                },
                breakpoints: {
                    768: {
                        spaceBetween: 100,
                    },
                },
                thumbs: {
                    swiper: {
                        el: ".thumbs-slide--office .swiper-container",
                        slideToClickedSlide: true,
                        slidesPerView: "4",
                    },
                },
            },

            roomOptions: {
                slidesPerView: 1,
                spaceBetween: 50,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                navigation: {
                    nextEl: ".main-slide--room .swiper-button-next",
                    prevEl: ".main-slide--room .swiper-button-prev",
                },
                breakpoints: {
                    768: {
                        spaceBetween: 100,
                    },
                },
                thumbs: {
                    swiper: {
                        el: ".thumbs-slide--room .swiper-container",
                        slideToClickedSlide: true,
                        slidesPerView: "4",
                    },
                },
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
